/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Unstable_Grid2';
import LinearProgress from '@mui/material/LinearProgress';

function AlertC(props) {
  const [progress, setProgress] = useState(0);

  if (props.loginA) {
    useEffect(() => {
      const timeId = setTimeout(() => {
        props.close();
      }, 4000);
      const timer = setInterval(() => {
        setProgress((oldProgress) => oldProgress + 11);
      }, 400);
      return () => {
        clearTimeout(timeId);
        clearInterval(timer);
      };
    }, []);
    return (
      <Collapse in={props.alertOn}>
        <Grid container spacing={2} className="outer-user-grid">
          <Grid xs={12} md={12}>
            <Alert severity="error" onClose={props.close}>
              <AlertTitle>{props.msg}{/* props.object.error */}</AlertTitle>
              <strong>{props.object.error_description}</strong>
              <br />
              <LinearProgress
                sx={{
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'red',
                  },
                }}
                variant="determinate"
                value={progress}
              />
            </Alert>
          </Grid>
        </Grid>
      </Collapse>
    );
  }

  if (!props.ok) {
    useEffect(() => {
      const timeId = setTimeout(() => {
        props.close();
      }, 4000);
      const timer = setInterval(() => {
        setProgress((oldProgress) => oldProgress + 11);
      }, 400);
      return () => {
        clearTimeout(timeId);
        clearInterval(timer);
      };
    }, []);
    return (
      <>
        <Collapse in={props.alertOn}>
          <Grid container spacing={2} className="outer-user-grid">
            <Grid xs={12} md={12}>
              <Alert severity="error" onClose={props.close}>
                <AlertTitle>{props.messageText} <strong>{props.message}</strong></AlertTitle>
                <LinearProgress
                  sx={{
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'red',
                    },
                  }}
                  variant="determinate"
                  value={progress}
                />
              </Alert>
            </Grid>
          </Grid>
        </Collapse>
        <br />
      </>
    );
  }

  if (props.ok) {
    useEffect(() => {
      const timeId = setTimeout(() => {
        props.close();
      }, 4000);
      const timer = setInterval(() => {
        setProgress((oldProgress) => oldProgress + 11);
      }, 400);
      return () => {
        clearTimeout(timeId);
        clearInterval(timer);
      };
    }, []);
    return (
      <>
        <Collapse in={props.alertOn}>
          <Grid container spacing={2} className="outer-user-grid">
            <Grid xs={12} md={12}>
              <Alert severity="success" onClose={props.close}>
                <AlertTitle>{props.messageText} <strong>{props.message}</strong></AlertTitle>
                <LinearProgress
                  sx={{
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'green',
                    },
                  }}
                  variant="determinate"
                  value={progress}
                />
              </Alert>
            </Grid>
          </Grid>
        </Collapse>
        <br />
      </>
    );
  }
}

export default AlertC;
