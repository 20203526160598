/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getUpsGroups, updateUpsGroups } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Form, FormGroup, Label, Container, Modal } from 'reactstrap';
import { Edit } from 'react-feather';
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function UpdateGroup({ width, group }) {
  const { basictoaster, primary, setUpsGroups, setLoading, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => { setOpen(true); };
  const handleClose = () => setOpen(false);

  const groupNameRef = useRef('');

  const updateGroupH = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will update the ups group'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const gObj = {
              id_group: group.id_group,
              customer_code: group.customer_code,
              group_name: groupNameRef.current.value,
            };
            setOpen(false);
            await updateUpsGroups(gObj).then(async (response) => {
              setLoading(true);
              getUpsGroups().then((resp) => {
                setUpsGroups(resp)
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Ups groups Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                SweetAlert.fire(
                    t('Updated') + '!',
                    `${response.Success}.`,
                    'success'
                );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error updating ups group')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster("error", `${t("Update ups groups Error")}`, err, () => {});});
          }
        });
  };

  return (
    <>
      <button className='imgButton' type="button" onClick={handleOpen}><Edit width={width} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <ScrollBar>
          <Container fluid={true} style={{ padding: '20px' }}>
            <h5>{t('Update group')}</h5><br />
            <Form style={{ margin: '10%', marginTop: '0%', marginBottom: '1%' }}>
              <FormGroup>
                <Label htmlFor="bottomname" className="col-form-label pt-0">{t('Group name')}</Label>
                <input className="form-control" type="text" id="bottomname" ref={groupNameRef} defaultValue={group.group_name} />
              </FormGroup>
              <Button
                variant="contained"
                type="button"
                onClick={updateGroupH}
                style={{ backgroundColor: primary }}
                className=""
              >
                {t('Update')}
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={handleClose}
                style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                className=""
              >
                {('close')}
              </Button>
            </Form>
          </Container>
        </ScrollBar>
      </Modal>
    </>
  );
}

export default UpdateGroup;