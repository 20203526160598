/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import { getUpsDevicesInfo, getUpsDevicesSerial } from '../../../../../api/api';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import { Spinner } from '../../../../../AbstractElements';
import UpsDashboard from './UpsDashboard/UpsDashboard'
import UpsInfoTable from './UpsInfoTable';
import { useParams } from 'react-router-dom';
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function UpsInfoPage() {
  const { serial } = useParams();
  const { basictoaster, upsDevicesInfo, setUpsDevicesInfo, device, setDevice } = useStateContext();
  const [infoLoading, setInfoLoading] = useState(false);
  const { t } = useTranslation();  

  useEffect(() => {
    const abortController = new AbortController();
    setInfoLoading(true);
    getUpsDevicesSerial(serial, abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setDevice(resp)
      }
    }).catch((err) => {basictoaster("error", t("Ups device info"), err, () => {})})
    getUpsDevicesInfo(serial, abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsDevicesInfo(resp)
        setInfoLoading(false);
      }
    }).catch((err) => {basictoaster("error", t("Ups device info Error"), err, () => {})})
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [basictoaster, serial, setDevice, setUpsDevicesInfo, t])

  // ALERT
  if (isObject(upsDevicesInfo) && containsError(upsDevicesInfo)) {
    basictoaster("error", t("Ups device info Error"), upsDevicesInfo.Error, setUpsDevicesInfo)
  }
  // ALERT
  if (isObject(device) && containsError(device)) {
    basictoaster("error", t("Ups device for serial Error"), device.Error, setDevice)
  }

  return (
    <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
      <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <ScrollBar>
                  {infoLoading ? (
                    <div className="loader-box">
                      <Spinner attrSpinner={{ className: 'loader-1' }} />
                    </div>
                  ) : (
                    upsDevicesInfo.length > 0 ? (
                      <>
                        <h4>{device.length > 0 && device[0].customer_name.toUpperCase()} \ {device.length > 0 && device[0].group_name.toUpperCase()} \ {upsDevicesInfo[0].ups_name.toUpperCase()}</h4>
                        <UpsDashboard upsDevicesInfo={upsDevicesInfo} />
                        <UpsInfoTable upsDevicesInfo={upsDevicesInfo} />
                      </>
                    ) : (
                      <p>{t('No ups device info found')}...</p>
                    )
                  )}
                </ScrollBar>
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
    </Container>
  );
}

export default UpsInfoPage;