import React, { Fragment, useState } from 'react';
import { English } from '../../../Constant';
import { useTranslation } from 'react-i18next';
import { LI } from '../../../AbstractElements';

const LanguageClass = () => {
    const [langdropdown, setLangdropdown] = useState(false);
    const { i18n } = useTranslation();
    const [selected, setSelected] = useState('en');

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        setSelected(lng);
    };

    const LanguageSelection = (language) => {
        if (language) {
            setLangdropdown(!language);
        } else {
            setLangdropdown(!language);
        }
    };
    return (
        <Fragment>
            <LI attrLI={{ className: 'onhover-dropdown' }} >
                <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
                    <div className="current_lang">
                        <div className="lang d-flex" onClick={() => LanguageSelection(langdropdown)}>
                            <i className={`flag-icon flag-icon-${selected === 'en' ? 'us' : selected === 'it' ? 'it' : selected}`}></i>
                            <span className="lang-txt">{selected}</span>
                        </div>
                    </div>
                    <div className={`more_lang onhover-show-div ${langdropdown ? 'active' : ''}`}>
                        <div className="lang" onClick={() => changeLanguage('en')}><i className="flag-icon flag-icon-us"></i><span className="lang-txt">{English}</span></div>
                        <div className="lang" onClick={() => changeLanguage('it')}><i className="flag-icon flag-icon-it"></i><span className="lang-txt">Italian</span></div>
                    </div>
                </div>
            </LI>
        </Fragment>
    );
};

export default LanguageClass;