/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import { getUpsNotifications, addUpsNotifications } from '../../../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import { Checkbox } from '@mui/material';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function AddUpsNotifications({ ups }) {
  const { basictoaster, primary, setUpsNotifications, setUpsNotifLoading, setExecuting } = useStateContext();
  const { t } =  useTranslation();
  const [open, setOpen] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [formData, setFormData] = useState({
    reference: '',
    mail: '',
    mail_enabled: false
  });
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    // init errors
    setErrors([]);
    //open
    setAnimation(true);
    setOpen(true);
  };
  const handleClose = () => {
    // init form data
    setFormData({
      reference: '',
      mail: '',
      mail_enabled: false
    });
    // close
    setOpen(false);
  }
  const handleCloseAnimation = () => {
    // close animation
    setAnimation(false);
  }
  
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for a basic email format validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const validateForm = useCallback(() => {
    const { reference, mail } = formData;
    const newErrors = {};

    if (!reference) {
      newErrors.reference = `${t('Reference is required')}`;
    }
    if (!mail) {
      newErrors.mail = `${t('Mail is required')}`;
    } else if (!isValidEmail(mail)) {
      newErrors.mail = `${t('Invalid email address')}`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, t]);

  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const addUserHandler = () => {
    SweetAlert.fire({
        title: `${t('Are you sure')}?`,
        text: `${t('This will add the ups notification')}`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const uObj = {
              serial: ups.serial,
              reference: formData.reference,
              mail: formData.mail,
              mail_enabled: formData.mail_enabled
            };
            setUpsNotifLoading(true);
            await addUpsNotifications(uObj).then(async (response) => {
              getUpsNotifications(ups.serial).then((resp) => {
                setUpsNotifications(resp)
                setUpsNotifLoading(false);
              }).catch((err) => { basictoaster("error", t("Ups notifications Error"), err, () => {}) });
              if (containsSuccess(response)) {
                SweetAlert.fire(
                    `${t('Added')}!`,
                    `${response.Success}.`,
                    'success'
                );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    `${t('Error from backend')}!`,
                    `${t('Error adding ups notification')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              handleCloseAnimation();
            }).catch((err) => { basictoaster("error", t("Error adding ups notification"), err, () => {}) });
          }
        });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      addUserHandler();
    }
  };

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>{t('Add ups notification')}</Button>
      <Collapse in={animation} onExited={handleClose}>
        <ScrollBar>
          {open && (
            <Container fluid={true} style={{ padding: '20px' }}>
              <Card sx={{boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
                <CardContent>
                  <h5>{t('Add ups notification')}</h5><br />
                  <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
                    <FormGroup>
                      <Label htmlFor="reference" className="col-form-label pt-0">{t('Reference')}</Label>
                      <input
                        className="form-control"
                        type="text"
                        id="reference"
                        name="reference"
                        value={formData.reference}
                        onChange={handleChangeText}
                      />
                      {errors.reference && <div style={{ color: 'red' }}>{errors.reference}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="mail" className="col-form-label pt-0">{t('Mail')}</Label>
                      <input
                        className="form-control"
                        type="text"
                        id="mail"
                        name="mail"
                        value={formData.mail}
                        onChange={handleChangeText}
                      />
                      {errors.mail && <div style={{ color: 'red' }}>{errors.mail}</div>}
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="mail_enabled" className="col-form-label pt-0">{t('Enabled')}?</Label>
                      <Checkbox
                        id="mail_enabled"
                        name="mail_enabled"
                        value={formData.mail_enabled}
                        checked={formData.mail_enabled}
                        onChange={handleChangeCheckBox}
                      />
                    </FormGroup>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ backgroundColor: primary }}
                      className=""
                    >
                      {t('Add')}
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleCloseAnimation}
                      style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                      className=""
                    >
                      {t('Cancel')}
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Container>
          )}
        </ScrollBar>
      </Collapse>
    </>
  );
}

export default AddUpsNotifications;