/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { updateUpsDevices, getApprovedUpsDevices } from '../../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Form, FormGroup, Label, Container, Modal } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import { Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}
function containsMessage(item) {
  return Object.prototype.hasOwnProperty.call(item, 'message');
}

function ChangeIntervalTime({ ups }) {
  const { basictoaster, primary, setUpsDevices, setLoading, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const [intervalTime, setIntervalTime] = useState(ups.interval_time);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
    setIntervalTime(ups.interval_time);
  };
  const handleClose = () => setOpen(false);

  const handleChangeTime = (e) => {
    const forbiddenChars = [',', '.', '+', '-'];
    const inputValue = e.target.value;
    for (const char of forbiddenChars) {
      if (inputValue.includes(char)) {
        // If any forbidden character is found, prevent it and clear the input value
        e.preventDefault();
        setIntervalTime('');
        return;
      }
    }
    // If no forbidden character is found, proceed with parsing the integer
    if (inputValue === '') {
      setIntervalTime(inputValue);
    } else {
      const parsedInt = parseInt(inputValue, 10);
      if (!isNaN(parsedInt)) {
        setIntervalTime(parsedInt);
      } else {
        setIntervalTime('');
      }
    }
  };

  const updateChangeH = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will change the interval time'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const devObj = {
              serial: ups.serial,
              customer_code: ups.customer_code,
              id_group: ups.id_group,
              api_pass: ups.api_pass,
              ack: ups.ack,
              interval_time: intervalTime,
              internal_ip: ups.internal_ip,
              external_ip: ups.external_ip,
              device_added_date: ups.device_added_date,
              device_approved_date: ups.device_approved_date,
              device_installed_date: ups.device_installed_date,
              needs_ssh_tunnel: ups.needs_ssh_tunnel,
              ssh_remote_port: ups.ssh_remote_port,
              ssh_opened_date: ups.ssh_opened_date,
            };
            setLoading(true);
            await updateUpsDevices(devObj).then(async (response) => {
              getApprovedUpsDevices().then((resp) => {
                setUpsDevices(resp)
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Interval time changed') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error changing interval time')}: ${response.Error}.`,
                    'error'
                );
              } else if (containsMessage(response)) {
                SweetAlert.fire(
                    t('Error from api') + '!',
                    `${t('Error changing interval time')}: ${response.message}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster("error", `${t("Update ups devices Error")}`, err, () => {});});;
          }
        });
  };

  return (
    <>
      <div
        onClick={handleOpen}
        style={{display: 'inline-block', marginLeft: '8px', cursor: 'pointer'}}
      >
        <Edit width={15} />
      </div>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <ScrollBar>
          <Container fluid={true} style={{ padding: '20px' }}>
            <h5>{t('Change time interval')}</h5><br />
            <Form style={{ margin: '0%', marginTop: '0%', marginBottom: '1%' }}>
              <FormGroup>
                <Label htmlFor="time" className="col-form-label pt-0">{t('Time interval')}</Label>
                <input className="form-control" type="number" id="time" onChange={handleChangeTime} value={intervalTime} />
              </FormGroup><br /> 
              {(intervalTime === 0 || intervalTime === '') && (
                <><h5 style={{ color: 'red' }}>{t('Interval time should be greater than one minute')}</h5><br /></>
              )}
              <Button
                variant="contained"
                type="button"
                onClick={updateChangeH}
                style={{ backgroundColor: primary }}
                className=""
                disabled={intervalTime === 0 || intervalTime === ''}
              >
                {t('Change')}
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={handleClose}
                style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                className=""
              >
                {t('close')}
              </Button>
            </Form>
          </Container>
        </ScrollBar>
      </Modal>
    </>
  );
}

export default ChangeIntervalTime;