/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Table } from 'reactstrap';
import UpdateGlobalConf from './UpdateGlobalConf';
import DelGlobalConf from './DelGlobalConf';
import { useTranslation } from 'react-i18next';

function GlobalConfTable({ globalConf }) {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <Table style={{ textAlign: 'center' }}>
        <thead className="table-primary table-responsive">
          <tr>
            <th>{t('Master pass')}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {globalConf.map((conf) => (
            <tr key={conf.id}>
              <td>{conf.master_api_password}</td>
              <td><UpdateGlobalConf gcId={conf.id} width="20px"/></td>
              <td><DelGlobalConf gcId={conf.id} width="20px"/></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default GlobalConfTable;