/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import { getUpsDevicesInfo, getApprovedUpsDevices } from '../../../../../api/api';
import { Container, Modal } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import { Spinner } from '../../../../../AbstractElements';
import UpsDashboard from './UpsDashboard/UpsDashboard'
import UpsInfoTable from './UpsInfoTable';
import { Info } from 'react-feather';
import { useTranslation } from 'react-i18next';
import UpsBinUpdate from './UpsBinUpdate/UpsBinUpdate';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function UpsInfo({ ups }) {
  const { basictoaster, primary, upsDevicesInfo, setUpsDevicesInfo, setDevice, setUpsDevices, setLoading } = useStateContext();
  const [open, setOpen] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const { t } = useTranslation();

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // set device
    setDevice([ups]);
    // get device info
    setInfoLoading(true);
    getUpsDevicesInfo(ups.serial, newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setUpsDevicesInfo(resp)
        setInfoLoading(false);
      }
    }).catch((err) => {basictoaster("error", t("Ups device info Error"), err, () => {})})
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    getApprovedUpsDevices().then((resp) => {
      setUpsDevices(resp)
      setLoading(false);
    }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
    abortController.abort();
  };

  // ALERT
  if (isObject(upsDevicesInfo) && containsError(upsDevicesInfo)) {
    basictoaster("error", t("Ups device info Error"), upsDevicesInfo.Error, setUpsDevicesInfo)
  }

  return (
    <>
      <div style={{display: 'inline-block', cursor: 'pointer', color: primary}} onClick={handleOpen}> <Info width={"20px"} /></div>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        style={{maxWidth: '85%', width: '100%' }}
        // style={{ width: '100%'}}
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <ScrollBar style={{ height: 880 }}>
          <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
            {infoLoading ? (
              <div className="loader-box">
                <Spinner attrSpinner={{ className: 'loader-1' }} />
              </div>
            ) : (
              upsDevicesInfo.length > 0 ? (
                <>
                  <h2>Ups "{ups.serial}"</h2>
                  <UpsDashboard upsDevicesInfo={upsDevicesInfo} />
                  <UpsInfoTable upsDevicesInfo={upsDevicesInfo} />
                  <UpsBinUpdate upsDevice={ups} />
                </>
              ) : (
                <p>{t('No ups device info found')}...</p>
              )
            )}<br />
            <Button variant='contained' style={{backgroundColor: 'gray'}} onClick={handleClose}>{t('Close')}</Button>
          </Container>
        </ScrollBar>
      </Modal>
    </>
  );
}

export default UpsInfo;