import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Table } from 'reactstrap';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTranslation } from 'react-i18next';
import { Check, X } from 'react-feather';

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const darkOrLigth = localStorage.getItem('mix_background_layout');
  let color = 'black';
  if (darkOrLigth === 'dark-only') { color = 'white' }

  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function UpsMailsQueueTable({ upsMailsQueue }) {
  const darkOrLigth = localStorage.getItem('mix_background_layout');
  let color = 'black';
  if (darkOrLigth === 'dark-only') { color = 'white' }
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - upsMailsQueue.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    if (date) {
      const formattedDate = new Date(date);
      return formattedDate.toLocaleDateString() + ' ' + formattedDate.toLocaleTimeString();
    }
    return '';
  };

  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <Table style={{ textAlign: 'center' }}>
        <thead className="table-primary table-responsive">
          <tr>
            <th>{t('From')}</th>
            <th>{t('To')}</th>
            <th>{t('Subject')}</th>
            <th>{t('Sent')}</th>
            <th>{t('Status')}</th>
            <th>{t('Insertion date')}</th>
            <th>{t('Sent date')}</th>
            {/* <th /> */}
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {(rowsPerPage > 0
            ? upsMailsQueue.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : upsMailsQueue
          ).map((mail) => (
            <tr key={mail.id}>
              <td>{mail.mail_from}</td>
              <td>{mail.mail_to}</td>
              <td>{mail.mail_subject}</td>
              <td>{mail.mail_sent ? <Check width={20} /> : <X width={20} />}</td>
              <td>{mail.communication_type}</td>
              <td>{formatDate(mail.row_insertion_date)}</td>
              <td>{formatDate(mail.mail_sent_date)}</td>
            </tr>
          ))}

          {emptyRows > 0 && (
            <tr style={{ height: 53 * emptyRows }}>
              <td colSpan={6} />
            </tr>
          )}
        </tbody>
        <TableFooter>
          <tr>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={upsMailsQueue.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              style={{ color: color }}
            />
          </tr>
        </TableFooter>
      </Table>
    </div>
  );
}

export default UpsMailsQueueTable;

// OLD TABLE

// import { useTable, useSortBy } from 'react-table';
// import { Table } from 'reactstrap';
// import { ArrowUp, ArrowDown, Check, X } from 'react-feather';

// function UpsMailsQueueTable({ upsMailsQueue }) {
//   const { t } = useTranslation();

//   const formatDate = (date) => {
//     if (date) {
//       const formattedDate = new Date(date);
//       return formattedDate.toLocaleDateString() + ' ' + formattedDate.toLocaleTimeString();
//     }
//     return '';
//   };

//   const columns = React.useMemo(
//     () => [
//       {
//         Header: t('From'),
//         accessor: 'mail_from',
//       },
//       {
//         Header: t('To'),
//         accessor: 'mail_to',
//       },
//       {
//         Header: t('Subject'),
//         accessor: 'mail_subject',
//       },
//       {
//         Header: t('Sent'),
//         accessor: 'mail_sent',
//         Cell: ({ cell: { value } }) => value ? <Check width={20} /> : <X width={20} />,
//       },
//       {
//         Header: t('Status'),
//         accessor: 'communication_type',
//       },
//       {
//         Header: t('Insertion date'),
//         accessor: 'row_insertion_date',
//         sortType: 'basic',
//         initialSortBy: [
//           {
//             id: 'row_insertion_date',
//             desc: true, // Default sorting in descending order
//           },
//         ],
//         Cell: ({ cell: { value } }) => formatDate(value),
//       },
//       {
//         Header: t('Sent date'),
//         accessor: 'mail_sent_date',
//         sortType: 'basic',
//         Cell: ({ cell: { value } }) => formatDate(value),
//       },
//     ],
//     [t]
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//   } = useTable(
//     {
//       columns,
//       data: upsMailsQueue,
//     },
//     useSortBy
//   );

//   return (
//     <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
//       <Table style={{ textAlign: 'center' }} {...getTableProps()}>
//         <thead className="table-primary table-responsive">
//           {headerGroups.map(headerGroup => (
//             <tr {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map(column => (
//                 <th
//                   {...column.getHeaderProps(column.getSortByToggleProps())}
//                 >
//                   {column.render('Header')}
//                   {column.isSorted ? (
//                     column.isSortedDesc ? (
//                       <ArrowDown width={20} />
//                     ) : (
//                       <ArrowUp width={20} />
//                     )
//                   ) : null}
//                 </th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody {...getTableBodyProps()}>
//           {rows.map(row => {
//             prepareRow(row);
//             return (
//               <tr {...row.getRowProps()}>
//                 {row.cells.map(cell => {
//                   return (
//                     <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                   );
//                 })}
//               </tr>
//             );
//           })}
//         </tbody>
//       </Table>
//     </div>
//   );
// }

// export default UpsMailsQueueTable;