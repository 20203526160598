/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useEffect } from 'react';
import DelUsers from './DelUpsNotifications';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import { getUpsNotifications, updateUpsNotifications } from '../../../../../api/api';
import { Edit, Check, X } from 'react-feather';
import { Checkbox } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}


function UNtableBody({ upsNotification, ups }) {
  const { basictoaster, setUpsNotifLoading, setUpsNotifications, setExecuting } = useStateContext();
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    reference: upsNotification.reference,
    mail: upsNotification.mail,
    mail_enabled: upsNotification.mail_enabled
  });
  const [errors, setErrors] = useState({});
  const [ldgUpdEnabled, setLdgUpdEnabled] = useState(false);

  const startEditing = () => {
    // init errors
    setErrors([]);
    // init data
    setFormData({
      reference: upsNotification.reference,
      mail: upsNotification.mail,
      mail_enabled: upsNotification.mail_enabled
    });
    //open
    setEditing(true);
  };
  const stopEditing = () => {
    // close
    setEditing(false);
  }
  
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const handleChangeCheckBoxUpdate = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
    updateEnabledH(checked);
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for a basic email format validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const validateForm = useCallback(() => {
    const { reference, mail } = formData;
    const newErrors = {};

    if (!reference) {
      newErrors.reference = `${t('Reference is required')}`;
    }
    if (!mail) {
      newErrors.mail = `${t('Mail is required')}`;
    } else if (!isValidEmail(mail)) {
      newErrors.mail = `${t('Invalid email address')}`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, t]);

  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])

  const updateEnabledH = (checked) => {
    setLdgUpdEnabled(true);
    const uObj = {
      id: upsNotification.id,
      serial: ups.serial,
      reference: formData.reference,
      mail: formData.mail,
      mail_enabled: checked
    };
    updateUpsNotifications(uObj).then(async (response) => {
      if (containsSuccess(response)) {
        basictoaster("success", `${t('Updated')}!`, `${response.Success}.`, () => {}) 
      } else if (containsError(response)) {
        basictoaster("error", `${t('Error from backend')}!`, `${t('Error updating ups notification')}: ${response.Error}.`, () => {}) 
      }
      setLdgUpdEnabled(false);
    }).catch((err) => { basictoaster("error", t("Error updating ups notification"), err, () => {}) });
  };

  const updateUserHandler = () => {
    setExecuting(true);
    const uObj = {
      id: upsNotification.id,
      serial: ups.serial,
      reference: formData.reference,
      mail: formData.mail,
      mail_enabled: formData.mail_enabled
    };
    setUpsNotifLoading(true);
    stopEditing();
    updateUpsNotifications(uObj).then(async (response) => {
      getUpsNotifications(ups.serial).then((resp) => {
        setUpsNotifications(resp)
        setUpsNotifLoading(false);
      }).catch((err) => { basictoaster("error", t("Ups notifications Error"), err, () => {}) });
      if (containsSuccess(response)) {
        basictoaster("success", `${t('Updated')}!`, `${response.Success}.`, () => {}) 
      } else if (containsError(response)) {
        basictoaster("error", `${t('Error from backend')}!`, `${t('Error updating ups notification')}: ${response.Error}.`, () => {}) 
      }
      setExecuting(false);
    }).catch((err) => { basictoaster("error", t("Error updating ups notification"), err, () => {}) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateUserHandler();
    }
  };

  return (
    <tr>
      <td>{upsNotification.serial}</td>
      {editing ? (
        <>
          <td>
            <input
              className="form-control"
              type="text"
              id="reference"
              name="reference"
              value={formData.reference}
              onChange={handleChangeText}
            />
            {errors.reference && <div style={{ color: 'red' }}>{errors.reference}</div>}
          </td>
          <td>
            <input
              className="form-control"
              type="text"
              id="mail"
              name="mail"
              value={formData.mail}
              onChange={handleChangeText}
            />
            {errors.mail && <div style={{ color: 'red' }}>{errors.mail}</div>}
          </td>
          <td style={{ verticalAlign: 'top' }}>
            <Checkbox
              id="mail_enabled"
              name="mail_enabled"
              value={formData.mail_enabled}
              checked={formData.mail_enabled}
              onChange={handleChangeCheckBox}
            />
          </td>
          <td>
            <button className='imgButton' type="button" onClick={handleSubmit}><Check width={20} /></button>
            <button className='imgButton' type="button" onClick={stopEditing}><X width={20} /></button>
          </td>
        </>
      ) : (
        <>
          <td>{upsNotification.reference}</td>
          <td>{upsNotification.mail}</td>
          <td style={{ verticalAlign: 'middle', position: 'relative', textAlign: 'center' }}>
            {ldgUpdEnabled && (
              <div style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            )}
            <Checkbox
              id="mail_enabled"
              name="mail_enabled"
              value={formData.mail_enabled}
              checked={formData.mail_enabled}
              onChange={handleChangeCheckBoxUpdate}
              disabled={ldgUpdEnabled}
            />
          </td>
          <td><button className='imgButton' type="button" onClick={startEditing}><Edit width={20} /></button></td>
        </>
      )}
      <td><DelUsers width="20px" uId={upsNotification.id} ups={ups} /></td>
    </tr>
  );
}

export default UNtableBody;