/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Modal } from 'reactstrap';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import '../../../../../App.css'
import { Spinner } from '../../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getUpsNotifications } from '../../../../../api/api';
import UpsNotificationsTable from './UpsNotificationsTable';
import AddUpsNotifications from './AddUpsNotifications';
import { useTranslation } from 'react-i18next';
import { Mail } from 'react-feather';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const UpsNotifications = ({ ups }) => {
  const { primary, basictoaster, upsNotifLoading, setUpsNotifLoading, upsNotifications, setUpsNotifications } = useStateContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // set device
    setUpsNotifLoading(true);
    getUpsNotifications(ups.serial, newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setUpsNotifications(resp)
        setUpsNotifLoading(false);
      }
    }).catch((err) => { basictoaster("error", t("Ups notifications Error"), err, () => {}) });
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
    abortController.abort();
  };

  // ALERT
  useEffect(() => {
    if (isObject(upsNotifications) && containsError(upsNotifications)) {
      basictoaster("error", t("Ups notifications Error"), upsNotifications.Error, setUpsNotifications)
    }
  }, [upsNotifications])

  return (
    <>
      <div style={{display: 'inline-block', cursor: 'pointer', color: primary}} onClick={handleOpen}> <Mail width={"20px"} /></div>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        // style={{ width: '100%'}}
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
          <AddUpsNotifications ups={ups} /><br /><br />
          {upsNotifLoading ? (
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-1' }} />
            </div>
          ) : (
            upsNotifications && upsNotifications.length > 0
              ? (
                <Grow in={!upsNotifLoading}><div>
                  <UpsNotificationsTable upsNotifications={upsNotifications} ups={ups} />
                </div></Grow>
              )
              : (
                <Grow in={!upsNotifLoading}>
                  <p>{t('No ups notifications found')}</p>
                </Grow>
              )
          )}
        </Container>
      </Modal>
    </>
  );
};

export default UpsNotifications;