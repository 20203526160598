/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Container } from 'reactstrap';
import Grid from '@mui/material/Grid';
import StatusChart from './StatusChart';
import LoadPctChart from './LoadPctChart';
import LineVLineFChart from './LineVLineFChart';
import BChargeChart from './BChargeChart';
import UpsDetails from './UpsDetails';

function UpsDashboard({ upsDevicesInfo }) {
  return (
    <Container fluid={true} style={{ padding: '20px', textAlign: 'center'}}>
      <Grid container spacing={2}> 
        <Grid item xs={12} sm={4}>
          <UpsDetails upsDevicesInfo={upsDevicesInfo} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatusChart />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LoadPctChart />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LineVLineFChart />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BChargeChart />
        </Grid>
      </Grid>
    </Container>
  );
}

export default UpsDashboard;


// {/* <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
// <h5>Dasboard: </h5>
// <Grid container spacing={2}>
//   <Grid item xs={4}>
//   </Grid>
//   <Grid item xs={4}>
//     <StatusChart upsDevicesInfo={upsDevicesInfo} />
//   </Grid>
//   <Grid item xs={4}>
//   </Grid>
// </Grid>
// <Grid container spacing={2}>
//   <Grid item xs={1}>
//   </Grid>
//   <Grid item xs={10}>
//     <LoadPctChart upsDevicesInfo={upsDevicesInfo} />
//   </Grid>
//   <Grid item xs={1}>
//   </Grid>
// </Grid>
// <Grid container spacing={2}>
//   <Grid item xs={1}>
//   </Grid>
//   <Grid item xs={10}>
//     <LineVLineFChart upsDevicesInfo={upsDevicesInfo} />
//   </Grid>
//   <Grid item xs={1}>
//   </Grid>
// </Grid>
// <Grid container spacing={2}>
//   <Grid item xs={1}>
//   </Grid>
//   <Grid item xs={10}>
//     <BChargeChart upsDevicesInfo={upsDevicesInfo} />
//   </Grid>
//   <Grid item xs={1}>
//   </Grid>
// </Grid>
// </Container> */}