import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
HighchartsExporting(Highcharts);

function RoundChart(props) {
  const chartRef = useRef(null);

  const options = {
    chart: {
      type: 'pie',
      height: '180px',
      backgroundColor: 'transparent'
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: props.num,
      style: {
        fontSize: '25px',
        color: props.color
      }
    },
    tooltip: {
      formatter: function() {
        const { point } = this;
        let color = point.color; // Default color
        if (point.y === 0) {
          color = '#CCCCCC'; // Set color to gray if value is 0
        }
        return `<span style="color:${color}">\u25CF</span> ${point.name}: <b>${point.y}</b>`;
      }
    },
    plotOptions: {
      pie: {
        borderWidth: 1, 
        size: '100%',
        innerSize: '40%',
        states: {
          hover: {
            halo: {
              size: -18
            }
          }
        },
        colors: [props.color],
        dataLabels: {
          enabled: false
        },
        spacing: [0, 0, 0, 0], // Remove the spacing
        margin: 0 // Remove the margin
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: [{
      name: props.text,
      data: [
        { name: props.text, y: props.num },
      ]
    }]
  };

  return (
    <div style={{ height: '170px', marginTop: '-25px' }} >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}

export default RoundChart;