/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import trash from '../Icons/trash.png';
import { getUsers, deleteUsers } from '../../../api/api';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function DelUsers({ width, uId }) {
  const { basictoaster, setUsers, setLoading, setExecuting } = useStateContext();
  const { t } = useTranslation();

  const deleteCustomerH = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('Once deleted, you will not be able to recover this user') + '!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            await deleteUsers(uId).then(async (response) => {
              if (response.Success) {
                  SweetAlert.fire(
                      t('Deleted'),
                      t('User deleted successfully'),
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error deleting user')}: ${response.Error}.`,
                    'error'
                );
              }
              setLoading(true);
              getUsers().then((resp) => {
                setUsers(resp)
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Users Error")}`, err, () => {});});
              setExecuting(false);
            });
          }
        }).catch((err) => {basictoaster("error", `${t("Delete users Error")}`, err, () => {});});;
  };

  return (
      <button className='imgButton' type="button" onClick={deleteCustomerH}><img src={trash} alt="trash" style={{ width: width }} /></button>
  );
}

export default DelUsers;
