/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Table } from 'reactstrap';
// import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import UNtableBody from './UNtableBody';

function UpsNotificationsTable({ upsNotifications, ups }) {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      {/* <ScrollBar> */}
        <Table style={{ textAlign: 'center' }}>
          <thead className="table-primary table-responsive">
            <tr>
              <th>{t('Serial')}</th>
              <th>{t('Reference')}</th>
              <th>{t('Mail')}</th>
              <th>{t('Enabled')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {upsNotifications.length > 0 && upsNotifications.map((upsNotification) => (
              <UNtableBody key={upsNotification.id} upsNotification={upsNotification} ups={ups} />
            ))}
          </tbody>
        </Table>
        <br />
      {/* </ScrollBar> */}
    </div>
  );
}

export default UpsNotificationsTable;