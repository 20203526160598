/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getUsers } from '../../../api/api';
import UsersTable from './UsersTable';
import AddUsers from './AddUsers';
import { useTranslation } from 'react-i18next';
import NoRole from '../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Users = () => {
  const { basictoaster, loading, setLoading, users, setUsers } = useStateContext();
  const abortController = new AbortController();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getUsers(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUsers(resp)
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Users Error")}`, err, () => {});});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  // ALERT
  useEffect(() => {
    if (isObject(users) && containsError(users)) {
      basictoaster("error", t("Users Error"), users.Error, setUsers)
    }
  }, [users])
  

  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 2 or 3
  if (role === '2' || role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Users")} parent="Ups" title={t("Users")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <AddUsers /><br /><br />
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  !isObject(users) && users.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <UsersTable users={users} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}>
                        <p>{t('No users found')}</p>
                      </Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Users;