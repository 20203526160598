/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getUpsGroups, addUpsGroups, getCustomersUser } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal, Input } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import { Spinner } from '../../../AbstractElements';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function AddUpsGroup() {
  const { basictoaster, primary, setUpsGroups, setLoading, customers, setCustomers, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const [cusLoading, setCusLoading] = useState(false);
  const [customerCode, setCustomerCode] = useState("noCode");
  const { t } = useTranslation();
  const [abortController, setAbortController] = useState(new AbortController());

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // open
    setCustomerCode("noCode")
    setCusLoading(true);
    getCustomersUser(newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        setCustomers(resp)
        setCusLoading(false);
      }
    })
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    abortController.abort();
  }

  function selectCustomer(event) {
    if (event.target.value === 'noCode') {
      setCustomerCode(event.target.value)
    } else {
      setCustomerCode(parseInt(event.target.value, 10));
    }
  }

  const groupNameRef = useRef('');

  const addGroupHandler = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will add the group'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const gbObj = {
              customer_code: customerCode,
              group_name: groupNameRef.current.value,
            };
            await addUpsGroups(gbObj).then(async (response) => {
              setLoading(true);
              getUpsGroups().then((resp) => {
                setUpsGroups(resp)
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Ups groups Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                SweetAlert.fire(
                    t('Added') + '!',
                    `${response.Success}.`,
                    'success'
                );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error adding group')}: ${response.Error}.`,
                    'error'
                );
              }
            });
          }
          setExecuting(false);
          setOpen(false);
        }).catch((err) => {basictoaster("error", `${t("Add ups groups Error")}`, err, () => {});});
  };

  // ALERT
  if (isObject(customers) && containsError(customers)) {
    basictoaster("error", t("Customers Error"), customers.Error, setCustomers)
  }

  console.log(customerCode)

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>{t('Add ups group')}</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <ScrollBar>
          <Container fluid={true} style={{ padding: '20px' }}>
            <h5>{t('Add group')}</h5><br />
            {cusLoading ? (
              <div className="loader-box">
                <Spinner attrSpinner={{ className: 'loader-1' }} />
              </div>
            ) : (
              <div style={{ margin: '10%', marginTop: '0%', marginBottom: '1%' }}>
                <FormGroup>
                  <Label className="col-form-label pt-0" htmlFor="seltemp" >{t('Customer')}</Label>
                  <Input onChange={selectCustomer} type="select" className="custom-select" name="seltemp" id="seltemp">
                    <option value="noCode" >{t('Select a customer')}...</option>
                    {customers && customers.length > 0 && customers.map((customer) => (
                      <option key={customer.customer_code} value={customer.customer_code} >{customer.customer_name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="bottomname" className="col-form-label pt-0">{t('Group name')}</Label>
                  <input className="form-control" type="text" id="bottomname" ref={groupNameRef} />
                </FormGroup><br />
                {customerCode === "noCode" && (<h4 style={{ color: 'red' }} >{t('Please select the customer')}</h4>)}
                <Button
                  variant="contained"
                  type="button"
                  onClick={addGroupHandler}
                  style={{ backgroundColor: primary }}
                  className=""
                  disabled={customerCode === "noCode"}
                >
                  {t('Add')}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleClose}
                  style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                  className=""
                >
                  {t('close')}
                </Button>
              </div>
            )}
          </Container>
        </ScrollBar>
      </Modal>
    </>
  );
}

export default AddUpsGroup;