 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getGlobalConf } from '../../../api/api';
import AddGlobalConf from './AddGlobalConf';
import GlobalConfTable from './GlobalConfTable';
import { useTranslation } from 'react-i18next';
import NoRole from '../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const GlobalConf = () => {
  const { basictoaster, loading, setLoading, globalConf, setGlobalConf} = useStateContext();
  const abortController = new AbortController();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getGlobalConf(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setGlobalConf(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Global conf Error")}`, err, () => {});});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  // ALERT
  if (isObject(globalConf) && containsError(globalConf)) {
    basictoaster("error", t("Global conf Error"), globalConf.Error, setGlobalConf)
  }
  
  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 2 or 3
  if (role === '2' || role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Global conf")} parent={t("Global conf")} title={t("Global conf")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {/* <AddBinVersion /><br /><br /> */}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  globalConf.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <GlobalConfTable globalConf={globalConf} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}><div>
                        <AddGlobalConf /><br /><br />
                        <p>{t('No global conf found, add one')} ...</p>
                      </div></Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GlobalConf;