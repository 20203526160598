import React from "react";
import { Modal } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';

export default function ProgressBarModal({ executing, closeExecuting }) {
  return (
    <Modal
      isOpen={executing}
      toggle={closeExecuting}
      centered
      backdropClassName={executing ? 'BDModalOpen' : 'BDModalClosed'}
      modalClassName={executing ? 'ModalOpen' : 'ModalClosed'}
    >
      <LinearProgress color="success" />
    </Modal>
  )
}