/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { addBinVersions, getBinVersions } from '../../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Form, FormGroup, Label, Container, Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

// Event handler to prevent whitespaces in the input
const handleKeyDown = (e) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

function AddBinVersion() {
  const { basictoaster, setBinVersions, primary, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); setBinHash(''); setBinName('')  };
  const handleClose = () => setOpen(false);
  const [binHash, setBinHash] = useState('');
  const [binVer, setBinVer] = useState('');
  const [binName, setBinName] = useState('');
  const { t } = useTranslation();

  const changheBinHash = (event) => {
    setBinHash(event.target.value.trim())
  }
  const changheBinVer = (event) => {
    setBinVer(event.target.value.trim())
  }
  const changheBinName = (event) => {
    setBinName(event.target.value.trim())
  }

  const addBinVerHandler = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will add the binary version'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            const bvObj = {
              binary_hash: binHash,
              binary_name: binName,
              binary_version: binVer,
            };
            setExecuting(true);
            setOpen(false);
            await addBinVersions(bvObj).then(async (response) => {
              getBinVersions().then((resp) => {
                  setBinVersions(resp);
              }).catch((err) => {basictoaster('error', `${t('Bin versions Error')}`, err, () => {})});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Created') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error adding the bin version')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster('error', `${t('Error adding the bin version')}`, err, () => {})});;
          }
        });
  };

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>{t('Add binary version')}</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <Form style={{ margin: '10%', marginTop: '0%', marginBottom: '1%' }}>
            <FormGroup>
              <Label htmlFor="binhash" className="col-form-label pt-0">{t('Binary hash')}</Label>
              <input className="form-control" onChange={changheBinHash} value={binHash} type="text" id="binhash" onKeyDown={handleKeyDown} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="binver" className="col-form-label pt-0">{t('Binary version')}</Label>
              <input className="form-control" onChange={changheBinVer} value={binVer} type="text" id="binver" onKeyDown={handleKeyDown} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="binname" className="col-form-label pt-0">{t('Binary name')}</Label>
              <input className="form-control" onChange={changheBinName} value={binName} type="text" id="binname" onKeyDown={handleKeyDown} />
            </FormGroup>
            {binHash === '' || binName === '' || binVer === '' ? (
              <Button
                variant="contained"
                type="button"
                disabled
                style={{ backgroundColor: primary }}
                className=""
              >
                {t('Add')}
              </Button>
            ) : (
              <Button
                variant="contained"
                type="button"
                onClick={addBinVerHandler}
                style={{ backgroundColor: primary }}
                className=""
              >
                {t('Add')}
              </Button>
            )}
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
              className=""
            >
              {t('close')}
            </Button>
          </Form>
        </Container>
      </Modal>
    </>
  );
}

export default AddBinVersion;
