import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
// import { firebase_app, auth0 } from '../Config/Config';
// import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Loader from '../Layout/Loader';
import { authRoutes } from './AuthRoutes';
import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import CircleLoadModal from '../Components/Pages/CircleLoadModal/CircleLoadModal';
import { useStateContext } from '../contexts/ContextProvider';
// import PrivateRoute from './PrivateRoute';
// import { useStateContext } from '../contexts/ContextProvider';

const PrivateWrapper = () => (localStorage.getItem('logged') ? <Outlet /> : <Navigate to="/login" />);

const Routers = () => {
        const { executing } = useStateContext();
        return (
                // <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
                <>      
                <CircleLoadModal  executing={executing}/>
                        <BrowserRouter basename={'/'}>
                                <>
                                        <Suspense fallback={<Loader />}>
                                                <Routes>
                                                        <Route exact path={'/login'} element={<Signin />} />
                                                        {authRoutes.map(({ path, Component }, i) => (
                                                                <Route path={path} element={Component} key={i} />
                                                        ))}
                                                        <Route element={<PrivateWrapper />}>
                                                                <Route path={`/*`} element={<LayoutRoutes />} />
                                                                <Route exact
                                                                        path={''}
                                                                        element={<Navigate to={'/general-ups-dashboard'} />}
                                                                />
                                                                <Route exact
                                                                        path={`/`}
                                                                        element={<Navigate to={'/general-ups-dashboard'} />}
                                                                />
                                                        </Route>
                                                </Routes>
                                        </Suspense>
                                </>
                        </BrowserRouter>
                </>
                // </Auth0Provider>
        );
};

export default Routers;