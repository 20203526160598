import React, { useContext, useState, Fragment } from 'react';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CheckContext from '../../_helper/Customizer';
import logo from '../../assets/images/logo/ups_central_logo_orr.svg'

const Leftbar = () => {

    const { mixLayout, toggleSidebar, toggleIcon } = useContext(CheckContext);
    const [toggle, setToggle] = useState(false);

    const openCloseSidebar = () => {
        setToggle(!toggle);
        toggleSidebar(toggle);
    };

    return (
        <Fragment>
            <div className="main-header-left">
                <div className="logo-wrapper" style={{ marginRight: '20%' }}>
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                        <img src={logo} style={{ width: '100%' }} alt='logo' />
                    </Link>
                </div>
                <div className="toggle-sidebar" onClick={() => openCloseSidebar()} >
                    <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};

export default Leftbar;