/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getCustomers, addCustomers } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Form, FormGroup, Label, Container, Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsMessage(item) {
  return Object.prototype.hasOwnProperty.call(item, 'message');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function AddCustomer() {
  const { basictoaster, primary, setCustomers, setLoading, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const customerCodeRef = useRef('');
  const customerNameRef = useRef('');

  const addCustomerHandler = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will add the customer'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const cusObj = {
              customer_code: parseInt(customerCodeRef.current.value),
              customer_name: customerNameRef.current.value,
              audit_dpo_json: "",
              audit_gdpr_json: "",
            };
            await addCustomers(cusObj).then(async (response) => {
              getCustomers().then((resp) => {
                setCustomers(resp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Customers Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Created') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error adding the customer')}: ${response.Error}.`,
                    'error'
                );
              } else if (containsMessage(response)) {
                SweetAlert.fire(
                    t('Error from api') + '!',
                    `${t('Error adding the customer')}: ${response.message}.`,
                    'error'
                );
              }
              setOpen(false);
              setExecuting(false);
            }).catch((err) => {basictoaster("error", `${t("Add customers Error")}`, err, () => {});});
          }
        });
  };

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>{t('Add customer')}</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <Form style={{ margin: '10%', marginTop: '0%', marginBottom: '1%' }}>
            <FormGroup>
              <Label htmlFor="code" className="col-form-label pt-0">{t('Customer code')}</Label>
              <input className="form-control" type="number" id="code" ref={customerCodeRef} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="shna" className="col-form-label pt-0">{t('Customer name')}</Label>
              <input className="form-control" type="text" id="shna" ref={customerNameRef} />
            </FormGroup>
            <Button
              variant="contained"
              type="button"
              onClick={addCustomerHandler}
              style={{ backgroundColor: primary }}
              className=""
            >
              {t('Add')}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
              className=""
            >
              {t('close')}
            </Button>
          </Form>
        </Container>
      </Modal>
    </>
  );
}

export default AddCustomer;
