/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

toast.configure();
  const basictoaster = (toastname, notifyTxt, notifyMsg, itemsDel) => {
    switch (toastname) {
      case 'success':
        toast.success(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'info':
        toast.info(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'warning':
        toast.warn(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'error':
        toast.error(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      default:
        break;
    }
  };

const SamplePage = () => {
  const { loading, setLoading, campaignsResp, setCampaignsResp } = useStateContext();

  // useEffect(async () => {
  //   await phishCampaignsFn(setCampaignsResp, setLoading);
  // }, [setCampaignsResp])

  // ALERT
  const campaignsIsObj = isObject(campaignsResp);
  let campaignRespIsErr = false;
  if (campaignsIsObj) { campaignRespIsErr = containsError(campaignsResp); }
  useEffect(() => {
    campaignRespIsErr && (basictoaster("error", "Campaigns Error", campaignsResp.Error, setCampaignsResp))
  }, [campaignRespIsErr]);

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle="Sample" parent="Sample" title="Page" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <h2>Sample page</h2>
                {/* <AddCampaign /><br /><br /> */}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  // !campaignsIsObj && campaignsResp.length > 0
                  //   ? (
                      // <Grow in={!loading}><div>
                      //   <CTableHead>
                      //     {campaignsResp.map((campaign) => (
                      //       // <CTableBody campaign={campaign} key={campaign.id} completeCamp={completeCampHandler} />
                      //       <CTableBody campaign={campaign} key={campaign.id} />
                      //     ))}
                      //   </CTableHead>
                      // </div></Grow>
                  //   )
                    // : (
                    //   <Grow in={!loading}>
                    //     <p>No campaigns found</p>
                    //   </Grow>
                    // )
                  <h1>content</h1>
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SamplePage;