/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Container, Row, Col } from 'reactstrap';
// import Grid from '@mui/material/Grid';
// import GeneralStatusChart from './Charts/GeneralStatusChart'
import { useStateContext } from '../../../../contexts/ContextProvider'
import Grow from '@mui/material/Grow';
import RoundChart from './Charts/RoundChart';
import { useTranslation } from 'react-i18next';


function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function DashBoardUpsGeneral() {
  const { upsDevicesInfo, statusCount } = useStateContext();
  const { t } = useTranslation();
  
  return (
    <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
      {containsError(statusCount) || containsError(upsDevicesInfo) ? (
        <p style={{ color: 'red' }} >{t('Failed')}</p>
      ) : (
        <>
          <h3>{t('General dasboard')}: </h3>
          <h5>{t('All the ups status')}:</h5>
          <div style={{ textAlign: 'center' }}>
            <Grow in={true}><div>
              <Row>
                <Col xl="2"></Col>
                <Col xl="2">
                  <div xs={2} style={{ color: '#069c56', paddingTop: '20px' }}>
                    <h6>ONLINE</h6>
                  </div>
                  <RoundChart num={statusCount.online && statusCount.online.length > 0 ? statusCount.online && statusCount.online.length : 0} text="Online" color='#069c56' />
                </Col>
                <Col xl="2">
                  <div xs={2} style={{ color: '#d3212c', paddingTop: '20px' }}>
                    <h6>ON BATT</h6>
                  </div>
                  <RoundChart num={statusCount.onbatt && statusCount.onbatt.length > 0 ? statusCount.onbatt && statusCount.onbatt.length : 0} text="On battery" color='#d3212c' />
                </Col>
                <Col xl="2">
                  <div xs={2} style={{ color: '#ff980e', paddingTop: '20px' }}>
                    <h6>COMM LOST</h6>
                  </div>
                  <RoundChart num={statusCount.commlost && statusCount.commlost.length > 0 ? statusCount.commlost && statusCount.commlost.length : 0} text="Communication lost" color='#ff980e' />
                </Col>
                <Col xl="2">
                  <div xs={2} style={{ color: '#00000', paddingTop: '20px' }}>
                    <h6>NO RESPONSE</h6>
                  </div>
                  <RoundChart num={statusCount.noresponse && statusCount.noresponse.length > 0 ? statusCount.noresponse && statusCount.noresponse.length : 0} text="No response" color='#000000' />
                </Col>
                <Col xl="2"></Col>
              </Row>
            </div></Grow>
          </div><br />
          {/* <Grid container spacing={2}>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
              <GeneralStatusChart upsDevicesInfo={upsDevicesInfo} />
            </Grid>
            <Grid item xs={4}>
            </Grid>
          </Grid> */}
          <br />
        </>
      )}
    </Container>
  );
}

export default DashBoardUpsGeneral;