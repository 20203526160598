/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getUsers, updateUsers, getCustomers } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { FormGroup, Label, Container, Modal, Input } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { Plus, Trash2 } from 'react-feather';
import { Edit } from 'react-feather';
import { Spinner } from '../../../AbstractElements';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function UpdateUser({ user,width }) {
  const { basictoaster, primary, setUsers, setExecuting, customers, setCustomers } = useStateContext();
  const [open, setOpen] = useState(false);
  const [currentUserCustomer, setCurrentUserCustomer] = useState('noCustomer');
  const [userCustomers, setUserCustomers] = useState([]);
  // set enabled if it already includes all customer
  const [allCustomersEnabled, setAllCustomersEnabled] = useState(JSON.parse(user.customers).includes('0-ALLCUSTOMERS'));
  const [cusLoading, setCusLoading] = useState(false);
  const [formData, setFormData] = useState({
    mail: user.mail,
    role: user.role,
    enabled: user.enabled
  });
  const [errors, setErrors] = useState({});
  const [abortController, setAbortController] = useState(new AbortController());

  const { t } = useTranslation();

  const handleOpen = () => {
    // Create a new AbortController instance
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    // set usersCustomers from previous
    setUserCustomers(JSON.parse(user.customers))
    // init form data
    setFormData({
      mail: user.mail,
      role: user.role,
      enabled: user.enabled
    });
    // get customers
    setCusLoading(true);
    getCustomers(newAbortController.signal).then((resp) => {
      if (!newAbortController.signal.aborted) {
        // we need to remove from the customer list the ones that has already been added to the userCustomers
        if (!containsError(resp)) {
          // givent that in the userCustomer arr the customers are saved with 'code-customer' we check like that
          setCustomers(resp.filter(cus => !JSON.parse(user.customers).includes(`${cus.customer_code}-${cus.customer_name}`)))
        } else {
          setCustomers(resp)
        }
        setCusLoading(false);
      }
    })
    // init all customers enabled
    setAllCustomersEnabled(JSON.parse(user.customers).includes('0-ALLCUSTOMERS'))
    // init errors
    setErrors([]);
    // open
    setOpen(true);
  };
  const handleClose = () => {
    // close
    setOpen(false);
    abortController.abort();
  }

  // CUSTOMERS STATES MANAGEMENT
  // if added to se userCustomers remove from the customers list
  function removeCusFromList(cusToRm) {
    // given that the userCustomers are saved with "code-customer" to check we need to re-create that
    setCustomers(customers.filter(cus => `${cus.customer_code}-${cus.customer_name}` !== cusToRm))
  }
  // if removed from userCustomers re insert in the customers list
  function reAddCusToList(reInsertCus) {
    // given that the userCustomers are saved with "code-customer" to re add to customer list we need to re create the customer obj
    const [codeValue, customerValue] = reInsertCus.split('-');
    let reAddCusObj = {
      customer_code: parseInt(codeValue),
      customer_name: customerValue
    }
    setCustomers(prevCustomers => [...prevCustomers, reAddCusObj])
  }

  // function to set the current customer when changing in the select
  function onChangeCustomer(event) {
    setCurrentUserCustomer(event.target.value);
  }

  // function to add current user customer to array
  function adduserCustomerToArr() {
    // rm form customer list
    removeCusFromList(currentUserCustomer);
    // add to arr
    setUserCustomers(prevArray => [...prevArray, currentUserCustomer]);
    // reset current customer
    setCurrentUserCustomer('noCustomer');
  }
  // function to remove a user customer from array
  function rmUserCustomerFromArr(userCustomerToRm) {
    // re add to customer list
    reAddCusToList(userCustomerToRm);
    // rm from userCustomers
    setUserCustomers(userCustomers.filter(userCus => userCus !== userCustomerToRm));
  }

  function handleChangeAllCustomers(e) {
    // handles the change of the all customers checkbox
    // set the enabled state
    setAllCustomersEnabled(e.target.checked);
    // resets current user customer
    setCurrentUserCustomer('noCustomer');
    // if is true sets the userCustomers arr to all customers
    if (e.target.checked) {
      setUserCustomers(['0-ALLCUSTOMERS'])
    } else {
      // else if is false resets the userCustomers array and resets customer list
      setUserCustomers([])
      getCustomers(abortController.signal).then((resp) => {
        if (!abortController.signal.aborted) {
          setCustomers(resp)
          setCusLoading(false);
        }
      })
    }
  }
  // CUSTOMERS STATES MANAGEMENT
  
  // FORMS ON CHANGE
  // dinamycally handle change using form input and set to formData field that was set in the input
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // dinamycally handle change using form input and set to formData field that was set in the input
  const handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  // FORMS ON CHANGE

  // validate form function callback
  const validateForm = useCallback(() => {
    const { mail, role } = formData;
    const newErrors = {};

    if (!mail) {
      newErrors.mail = `${t('Mail is required')}`;
    }
    if (!role) {
      newErrors.role = `${t('Role is required')}`;
    }
    if (userCustomers.length === 0) {
      newErrors.customers = `${t('At least one customers is required')}`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, t, userCustomers]);

  // validate every time the formData changes
  useEffect(() =>  {
    validateForm();
  }, [formData, validateForm])  

  // function to call api and add the user
  const updateUserHandler = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will add the user'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const uObj = {
              id: user.id,
              mail: formData.mail,
              role: parseInt(formData.role),
              customers: JSON.stringify(userCustomers),
              enabled: formData.enabled,
            };
            await updateUsers(uObj).then(async (response) => {
              getUsers().then((resp) => {
                setUsers(resp)
              }).catch((err) => {basictoaster("error", `${t("Users Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                // init form data
                setFormData({
                  mail: user.mail,
                  role: user.role,
                  enabled: user.enabled
                });
                setCurrentUserCustomer('noCustomer');
                SweetAlert.fire(
                    t('Added') + '!',
                    `${response.Success}.`,
                    'success'
                );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error adding user')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              setOpen(false);
            }).catch((err) => {basictoaster("error", `${t("Add users Error")}`, err, () => {});});
          }
        });
  };

  // function to call when submitting the form that validates before calling the api handler function
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateUserHandler();
    }
  };

  return (
    <>
      <button className='imgButton' type="button" onClick={handleOpen}><Edit width={width} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <ScrollBar>
          <Container fluid={true} style={{ padding: '20px' }}>
            <h5>{t('Update User')}</h5><br />
            {cusLoading ? (
              <div className="loader-box">
                <Spinner attrSpinner={{ className: 'loader-1' }} />
              </div>
            ) : (
              <form style={{ margin: '10%', marginTop: '1%', marginBottom: '1%' }} onSubmit={handleSubmit} >
                {/* MAIL */}
                <FormGroup>
                  <Label htmlFor="mail" className="col-form-label pt-0">{t('Mail')}</Label>
                  <input
                    className="form-control"
                    type="text"
                    id="mail"
                    name="mail"
                    value={formData.mail}
                    onChange={handleChangeText}
                  />
                  {errors.mail && <div style={{ color: 'red' }}>{errors.mail}</div>}
                </FormGroup>
                {/* ROLE */}
                <FormGroup>
                  <Label htmlFor="role" className="col-form-label pt-0">{t('Role')}</Label>
                  <Input
                    className="form-control"
                    type="select"
                    id="role"
                    name="role"
                    value={formData.role}
                    onChange={handleChangeText}
                  >
                    <option value={1} >1</option>
                    <option value={2} >2</option>
                    <option value={3} >3</option>
                  </Input>
                  {errors.role && <div style={{ color: 'red' }}>{errors.role}</div>}
                </FormGroup>
                {/* CUSTOMERS */}
                <FormGroup>
                  <Label htmlFor="customers" className="col-form-label pt-0" >{t('Customer')}</Label>
                  <Input
                    className="custom-select"
                    type="select"
                    id="customers"
                    name="customers"
                    onChange={onChangeCustomer}
                    disabled={allCustomersEnabled}
                  >
                    <option value="noCustomer" >{t('Select a customer')}...</option>
                    {customers && customers.length > 0 && customers.map((customer) => (
                      <option key={customer.customer_code} value={`${customer.customer_code}-${customer.customer_name}`}>{customer.customer_name}</option>
                    ))}
                  </Input>
                  {currentUserCustomer === 'noCustomer' ? (
                    <div>{!allCustomersEnabled && t('Please select a customer')}</div>
                  ) : (
                    <button type='button' className='imgButton' onClick={adduserCustomerToArr}><Plus width={20} /></button>
                  )}
                  {!allCustomersEnabled && userCustomers.length > 0 && (
                    userCustomers.map((uc) => (
                      <div key={uc}><p>{uc}</p><button type='button' className='imgButton' onClick={() => {rmUserCustomerFromArr(uc)}}><Trash2 width={20}/></button></div>
                    ))
                  )}
                  <br />
                  <Label htmlFor="allcus" className="col-form-label pt-0">{t('All customers')}</Label>
                  <br />
                  <input
                    className=""
                    type="checkbox"
                    id="allcus"
                    name="allcus"
                    value={allCustomersEnabled}
                    checked={allCustomersEnabled}
                    onChange={handleChangeAllCustomers}
                  />
                  {errors.customers && <div style={{ color: 'red' }}>{errors.customers}</div>}
                </FormGroup>
                {/* ENABLED */}
                <FormGroup>
                  <Label htmlFor="enabled" className="col-form-label pt-0">{t('Enabled')}</Label>
                  <br />
                  <input
                    className=""
                    type="checkbox"
                    id="enabled"
                    name="enabled"
                    value={formData.enabled}
                    checked={formData.enabled}
                    onChange={handleChangeCheckBox}
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: primary }}
                  className=""
                >
                  {t('Update')}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleClose}
                  style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                  className=""
                >
                  {t('close')}
                </Button>
              </form>
            )}
          </Container>
        </ScrollBar>
      </Modal>
    </>
  );
}

export default UpdateUser;