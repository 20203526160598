/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import trash from '../../Icons/trash.png';
import { getBinVersions, deleteBinVersions } from '../../../../api/api';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function DelBinVersion({ vId, width }) {
  const { basictoaster, setBinVersions, setExecuting } = useStateContext();
  const { t } = useTranslation();

  const deleteBinVer = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('Once deleted, you will not be able to recover this binary version'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            await deleteBinVersions(vId).then(async (response) => {
              getBinVersions().then((resp) => {
                  setBinVersions(resp);
              }).catch((err) => {basictoaster('error', `${t('Bin versions Error')}`, err, () => {})});
              if (response.Success) {
                  SweetAlert.fire(
                      t('Deleted'),
                      t('Binary version deleted successfully'),
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error deleting binary version')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster('error', `${t('Error deleting binary version')}`, err, () => {})});;
          }
        });
  };

  return (
      <button className='imgButton' type="button" onClick={deleteBinVer}><img src={trash} alt="trash" style={{ width: width }} /></button>
  );
}

export default DelBinVersion;
