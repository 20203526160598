/* eslint-disable object-property-newline */
import React, { createContext, useContext, useState } from 'react';
import ConfigDB from '../Config/ThemeConfig';
import { toast } from 'react-toastify';
toast.configure();
  const basictoaster = (toastname, notifyTxt, notifyMsg, itemsDel) => {
    switch (toastname) {
      case 'success':
        toast.success(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'warning':
        toast.warn(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      case 'error':
        toast.error(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      default:
        break;
    }
  };

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
  const [loginResp, setLoginResp] = useState({});
  const [alertOn, setAlertOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [upsGroups, setUpsGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [globalConf, setGlobalConf] = useState([]);
  const [upsDevices, setUpsDevices] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [upsDevicesInfo, setUpsDevicesInfo] = useState([]);
  const [upsDevicesInfoClone, setUpsDevicesInfoClone] = useState(upsDevicesInfo);
  const [statusCount, setStatusCount] = useState([]);
  const [device, setDevice] = useState([]);
  const [upsNotifications, setUpsNotifications] = useState([]);
  const [upsNotifLoading, setUpsNotifLoading] = useState(false);
  const [upsMailsQueue, setUpsMailsQueue] = useState([]);
  const [binVersions, setBinVersions] = useState([])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{
      basictoaster,
      primary,
      loading, setLoading,
      executing, setExecuting,
      loginResp, setLoginResp,
      alertOn, setAlertOn,
      customers, setCustomers,
      searchTerm, setSearchTerm,
      upsGroups, setUpsGroups,
      users, setUsers,
      globalConf, setGlobalConf,
      upsDevices, setUpsDevices,
      customerGroups, setCustomerGroups,
      upsDevicesInfo, setUpsDevicesInfo,
      upsDevicesInfoClone, setUpsDevicesInfoClone,
      statusCount, setStatusCount,
      device, setDevice,
      upsNotifications, setUpsNotifications,
      upsNotifLoading, setUpsNotifLoading,
      upsMailsQueue, setUpsMailsQueue,
      binVersions, setBinVersions,
    }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
