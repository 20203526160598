 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useStateContext } from '../../../../contexts/ContextProvider';
import '../../../../App.css'
import { Spinner } from '../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getAllUpsDevicesInfo, getAllUpsStatus } from '../../../../api/api';
// import AddGlobalConf from './AddGlobalConf';
import DashBoardUpsGeneral from './DashBoardUpsGeneral';
import { useTranslation } from 'react-i18next';
 
function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const GeneralUpsDashboard = () => {
  const { basictoaster, loading, setLoading, upsDevicesInfo, setUpsDevicesInfo, statusCount, setStatusCount } = useStateContext();
  const { t } = useTranslation();
  const abortController = new AbortController();

  useEffect(() => {
    setLoading(true);
    getAllUpsDevicesInfo(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsDevicesInfo(resp);
        getAllUpsStatus(abortController.signal).then((resp) => {
          if (!abortController.signal.aborted) {
            setStatusCount(resp)
            setLoading(false);
          }
        }).catch((err) => {basictoaster("error", `${t("All ups status Error")}`, err, () => {});});
      }
    }).catch((err) => {basictoaster("error", `${t("All ups devices info Error")}`, err, () => {});});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  // ALERT
  if (isObject(upsDevicesInfo) && containsError(upsDevicesInfo)) {
    basictoaster("error", t("All ups devices info Error"), upsDevicesInfo.Error, setUpsDevicesInfo)
  }
  // ALERT
  if (isObject(statusCount) && containsError(statusCount)) {
    basictoaster("error", t("All ups status Error"), statusCount.Error, setStatusCount)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Ups dashboard")} parent="Ups" title="Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  upsDevicesInfo && upsDevicesInfo.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <DashBoardUpsGeneral />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}><div>
                        <p>{t('No ups devices info found')}</p>
                      </div></Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralUpsDashboard;