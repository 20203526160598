/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Table } from 'reactstrap';
// import UpdateGlobalConf from './UpdateGlobalConf';
// import DelGlobalConf from './DelGlobalConf';
import ApproveAck from '../ManageACK/ApproveAck';
import DelUpsDevice from '../Approved/DelUpsDevice';
import { useTranslation } from 'react-i18next';

function UnapprovedUpsDevicesTable({ upsDevices }) {
  const unapprovedUpsDevices = upsDevices.filter(obj => obj.ack === "Unapproved ups device");
  const { t } = useTranslation();
  if (unapprovedUpsDevices.length === 0) {
    return (
      <p>{t('No unapproved ups devices found')}</p>
    )
  }
  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <Table style={{ textAlign: 'center' }}>
        <thead className="table-primary table-responsive">
          <tr>
            <th>{t('Serial')}</th>
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {unapprovedUpsDevices.map((ups, index) => (
            <tr key={index}>
              <td>{ups.serial}</td>
              <td><div className='unapproved'>{ups.ack}</div></td>
              <td><ApproveAck width="25px" ups={ups} /></td>
              <td><DelUpsDevice serial={ups.serial} width="20px" approved={false} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default UnapprovedUpsDevicesTable;