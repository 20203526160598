/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check, RefreshCcw } from 'react-feather';
import { useStateContext } from '../../../../../../contexts/ContextProvider';
import ScrollBar from 'react-perfect-scrollbar';
import Switch from '@mui/material/Switch';
import { getUpsDevicesInfo, setUpsNeedsSsh, getApprovedUpsDevices, getUpsDevicesSerial } from '../../../../../../api/api';
import { Button } from '@mui/material';
import { LinearProgress } from '@mui/material';

function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function UpsDetails({ upsDevicesInfo, selectedUpsDevice }) {
  const { primary, basictoaster, device, setDevice, setUpsDevicesInfoClone, setUpsDevicesInfo, setUpsDevices, setExecuting } = useStateContext();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sshEnabled, setSshEnabled] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { t } = useTranslation();

  // function to refresh data of this ups
  const handleRefreshThisUps = () => {
    // refresh only if is a single ups and not a multi comparison
    if (!selectedUpsDevice && device.length > 0) {
      setRefreshing(true);
      getUpsDevicesSerial(device[0].serial).then((resp) => {
        setDevice(resp);
      }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {})});
      getUpsDevicesInfo(device[0].serial).then((resp) => {
        setUpsDevicesInfo(resp)
        setRefreshing(false);
      }).catch((err) => {basictoaster("error", t("Ups device info Error"), err, () => {})});
    }
  }

  const handleSwitchChange = (e, serial) => {
    setExecuting(true);
    setSshEnabled(e.target.checked);
    // set needs ssh based on the switch status
    setUpsNeedsSsh(serial, e.target.checked).then((resp) => {
      if (containsSuccess(resp)) {
        basictoaster("success", "Success", resp.Success, () => {})
        // get ups devices to apply in the ui the modifies made to that field
        getApprovedUpsDevices().then((resp) => {
          setUpsDevices(resp);
          setExecuting(false);
        }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
      } else {
        // if is error re-call api to reset switch
        getUpsDevicesInfo(serial).then((resp) => {
          setUpsDevicesInfo(resp)
        }).catch((err) => {basictoaster("error", t("Ups device info Error"), err, () => {})})
      }
    }).catch((err) => {basictoaster("error", t("Ups device info Error"), err, () => {})})
  };

  // Set the sshEnabled if it was already enabled
  useEffect(() => {
    if (device.length > 0) {
      setSshEnabled(device[0].needs_ssh_tunnel);
    }
  }, [device])

  // default set the time interval of the shown data
  useEffect(() => {
    const now = new Date();
    now.setHours(now.getHours() + 2)
    const nowDateString = now.toISOString().slice(0, 16);

    // Get the date minus the difference
    const nowDifference = new Date(now);
    nowDifference.setHours(now.getHours() - 4);
    const nowDifferenceDateString = nowDifference.toISOString().slice(0, 16);

    setToDate(nowDateString);
    setFromDate(nowDifferenceDateString);
    const filteredData = upsDevicesInfo.filter(item => {
      const addedDate = item.added_date;
      return addedDate >= nowDifferenceDateString && addedDate <= nowDateString;
    });
    setUpsDevicesInfoClone(filteredData)
  }, [setUpsDevicesInfoClone, upsDevicesInfo]);

  const filterData = () => {
    const filteredData = upsDevicesInfo.filter(item => {
      const addedDate = item.added_date;
      return addedDate >= fromDate && addedDate <= toDate;
    });
    setUpsDevicesInfoClone(filteredData)
  }
  
  return (
    <>
      <Card sx={{position: "relative", height: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
        <CardContent>
        <Grid container style={{ backgroundColor: "#f5f5f5", padding: "16px"}}>
          <Grid item xs={12} sm={2}>
            <label>{t('From Date')}:</label>
          </Grid>
          <Grid item xs={12} sm={3}>
          <input
              className="form-control"
              type="datetime-local"
              value={fromDate}
              onChange={e => setFromDate(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <label>{t('To Date')}:</label>
          </Grid>
          <Grid item xs={12} sm={3}>
          <input
              className="form-control"
              type="datetime-local"
              value={toDate}
              onChange={e => setToDate(e.target.value)} /> 
          </Grid>
          <Grid item xs={12} sm={2}>
            <button className='imgButton' onClick={filterData}><Check width={20} /></button>
            <button className='imgButton' onClick={() => {setUpsDevicesInfoClone(upsDevicesInfo)}} ><RefreshCcw width={20} /></button>
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
            UPS details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* IF selectedUpsDevice IS PASSED TO PROPS IT MEANS THAT IS FROM MULTIPLE UPS COMPARATOR */}
            {selectedUpsDevice ? (
              <ScrollBar style={{ maxHeight: '200px' }} >
                <hr style={{marginRight: "10%", marginLeft: "10%"}}/>
                {selectedUpsDevice.length > 0 && selectedUpsDevice.map((multipledevice, index) => (
                  <div key={multipledevice.serial}>
                    <b>UPS: {index + 1}</b><br />
                    <b>S/N:</b> {multipledevice.serial}<br></br>
                    <b>{t('UPS name')}:</b> {multipledevice.ups_name}<br></br>
                    <b>{t('Customer')}:</b> {multipledevice.customer_code} | {multipledevice.customer_name}<br></br>
                    <b>{t('Group')}:</b> {multipledevice.id_group} | {multipledevice.group_name}<br></br>
                    <b>{t('Installation date')}:</b> {`${new Date(multipledevice.device_installed_date).toLocaleDateString()} ${new Date(multipledevice.device_installed_date).toLocaleTimeString() }`}<br></br>
                    <hr style={{marginRight: "10%", marginLeft: "10%"}}></hr>
                  </div>
                ))}
              </ScrollBar>
            ) : (
              <>
                {/* ELSE IF IS NOT PASSED IT MEANS THAT IS A NORMAL UPS DETAIL */}
                {device.length > 0 && (
                  <>
                    <Button variant='contained' style={{ backgroundColor: primary }} onClick={handleRefreshThisUps}>{t('Refresh')}</Button><br /><br />
                    {refreshing && <div style={{ width: '30%', margin: 'auto' }}><LinearProgress /><br /></div>}
                    <b>S/N:</b> {device[0].serial}<br></br>
                    <b>{t('UPS name')}:</b> {device[0].ups_name}<br></br>
                    <hr style={{marginRight: "10%", marginLeft: "10%"}}></hr>
                    <b>{t('Customer')}:</b> {device[0].customer_code} | {device[0].customer_name}<br></br>
                    <b>{t('Group')}:</b> {device[0].id_group} | {device[0].group_name}<br></br>
                    <hr style={{marginRight: "10%", marginLeft: "10%"}}></hr>
                    <b>{t('Installation date')}:</b> {`${new Date(device[0].device_installed_date).toLocaleDateString()} ${new Date(device[0].device_installed_date).toLocaleTimeString() }`}<br></br>
                    <b>{t('Local IP address')}:</b> {device[0].internal_ip}<br></br>
                    <b>{t('Public IP address')}:</b> {device[0].external_ip}<br></br>
                    <hr style={{marginRight: "10%", marginLeft: "10%"}}></hr>
                    {sshEnabled && (
                      <>
                        <b>SSH: </b>
                        {device[0].ssh_remote_port === 0 || device[0].ssh_remote_port === null ? (
                          t('Waiting... click refresh to check')
                        ) : (
                          `backbone.upscentral.it:${device[0].ssh_remote_port}`
                        )}
                        <br />
                      </>
                    )}
                    <b>{t('Enabled')}: </b><Switch checked={sshEnabled} onChange={(e) => {handleSwitchChange(e, device[0].serial)}} color="primary" inputProps={{ 'aria-label': 'controlled' }} /><br />
                    <b>{t('Last ssh opened date')}:</b> {`${new Date(device[0].ssh_opened_date).toLocaleDateString()} ${new Date(device[0].ssh_opened_date).toLocaleTimeString() }`}<br></br>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default UpsDetails;