/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Unstable_Grid2';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getCustomers, getCustomersName } from '../../../api/api';
import { useTranslation } from 'react-i18next';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function SearchAppBar() {
  const { basictoaster, setCustomers, setLoading, searchTerm, setSearchTerm } = useStateContext();
  const abortController = new AbortController();
  const { t } = useTranslation();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      if (searchTerm !== '') {
        getCustomersName(searchTerm, abortController.signal).then((resp) => {
          if (!abortController.signal.aborted) {
            setCustomers(resp);
            setLoading(false);
          }
        }).catch((err) => {basictoaster("error", `${t("Customers Error")}`, err, () => {});});
      } else {
        getCustomers(abortController.signal).then((resp) => {
          if (!abortController.signal.aborted) {
            setCustomers(resp);
            setLoading(false);
          }
        }).catch((err) => {basictoaster("error", `${t("Customers Error")}`, err, () => {});});
      }
    }, 700);

    return () => {clearTimeout(delayDebounceFn); abortController.abort(); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, setCustomers, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid xs={4} />
      <Grid xs={4}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={t("Search")}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Search>
      </Grid>
      <Grid xs={4} />
    </Grid>
  );
}
