/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getCustomers, updateCustomer } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Form, FormGroup, Label, Container, Modal } from 'reactstrap';
import { Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsMessage(item) {
  return Object.prototype.hasOwnProperty.call(item, 'message');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function UpdateCustomer({ customer, width }) {
  const { basictoaster, primary, setCustomers, setLoading } = useStateContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const customerCodeRef = useRef('');
  const customerNameRef = useRef('');

  const updateCustomerHandler = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will update the customer'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            const cusObj = {
              customer_code: parseInt(customerCodeRef.current.value),
              customer_name: customerNameRef.current.value,
              audit_dpo_json: customer.audit_dpo_json,
              audit_gdpr_json: customer.audit_gdpr_json,
            };
            setOpen(false);
            await updateCustomer(cusObj).then(async (response) => {
              getCustomers().then((resp) => {
                setCustomers(resp);
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Customers Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Updated') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error updating the customer')}: ${response.Error}.`,
                    'error'
                );
              } else if (containsMessage(response)) {
                SweetAlert.fire(
                    t('Error from api') + '!',
                    `${t('Error updating the customer')}: ${response.message}.`,
                    'error'
                );
              }
            }).catch((err) => {basictoaster("error", `${t("Update customers Error")}`, err, () => {});});
          }
        });
  };

  return (
    <>
      <button className='imgButton' type="button" onClick={handleOpen}><Edit width={width} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <Form style={{ margin: '10%', marginTop: '0%', marginBottom: '1%' }}>
            <FormGroup>
              <Label htmlFor="code" className="col-form-label pt-0">{t('Customer code')}</Label>
              <input className="form-control" defaultValue={customer.customer_code} type="number" id="code" ref={customerCodeRef} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="shna" className="col-form-label pt-0">{t('Customer name')}</Label>
              <input className="form-control" defaultValue={customer.customer_name} type="text" id="shna" ref={customerNameRef} />
            </FormGroup>
            <Button
              variant="contained"
              type="button"
              onClick={updateCustomerHandler}
              style={{ backgroundColor: primary }}
              className=""
            >
              {t('Update')}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
              className=""
            >
              {t('close')}
            </Button>
          </Form>
        </Container>
      </Modal>
    </>
  );
}

export default UpdateCustomer;
