import React, { Fragment, useEffect, useState } from 'react';
import { Settings } from 'react-feather';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL, P } from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';

function isObject(item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Profile = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const userRoles = JSON.parse(localStorage.getItem('auth0_user_roles'));
    const rolesIsObj = isObject(userRoles);

    useEffect(() => {
        const Roles = JSON.parse(localStorage.getItem('auth0_user_roles'));
        if (Roles) {
          if (Roles.includes('Admin')) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }
      }, []);

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                {/* <a className="setting-primary" href="#javascript">
                    <Settings />
                </a> */}
                <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
                    <Image attrImage={{ className: 'img-90 rounded-circle', src: userData.picture, alt: '' }} />
                    <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >{userData.nickname}</H6>
                    {isAdmin
                        && (
                            <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >Admin</H6>
                        )}
                </Link>
                <H6 attrH6={{ className: 'mt-3 f-14 f-w-600' }} >Enabled roles</H6>
                <UL attrUL={{ className: 'flex-row simple-list' }}>
                    {!rolesIsObj && (userRoles.map((role, index) => (
                        <li key={index}>
                            <P>{role}</P>
                        </li>
                    )))}
                </UL>
            </div>
        </Fragment >
    );
};

export default Profile;