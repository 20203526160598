import React, { Fragment, useEffect, useState, useContext, useMemo } from 'react';
import SidebarMenu from './SidebarMenu';
import CustomizerContext from '../../_helper/Customizer';
import Profile from './Profile';
import { Users, Settings, Battery, Mail, Activity } from 'react-feather';
import { useTranslation } from 'react-i18next';

const SideBarLayout = (props) => {
  const { toggleIcon } = useContext(CustomizerContext);
  const [currentUrl] = useState(window.location.pathname);
  const id = window.location.pathname.split('/').pop();
  const { t } = useTranslation();

  let role = localStorage.getItem('db_user_role');

  const MENUITEMS = useMemo(() => {
    let items = [
      {
          menutitle: t('Ups dashboard'),
          menucontent: 'Ups dashboard',
          Items: [
              {
                  title: t('Ups dashboard'), icon: Battery, type: 'link', path: '/general-ups-dashboard'
              },
          ]
      },
      {
          menutitle: t('Ups management'),
          menucontent: 'Ups management',
          Items: [
              {
                  title: t('Unapproved ups devices'), icon: Battery, type: 'link', path: '/unapproved-ups-devices'
              },
              {
                  title: t('Approved ups devices'), icon: Battery, type: 'link', path: '/approved-ups-devices'
              },
              {
                  title: t('Ups for customer'), icon: Battery, type: 'link', path: '/approved-ups-customers-devices'
              },
              {
                  title: t('Binaries versions'), icon: Activity, type: 'link', path: '/bin-versions'
              },
          ]
      },
      {
          menutitle: t('Groups'),
          menucontent: 'Groups',
          Items: [
              {
                  title: t('Groups'), icon: Users, type: 'link', path: '/ups-groups'
              },
          ]
      },
      {
          menutitle: t('Users'),
          menucontent: 'Users',
          Items: [
              {
                  title: t('Users'), icon: Users, type: 'link', path: '/users'
              },
          ]
      },
      {
          menutitle: t('Customers'),
          menucontent: 'Customers',
          Items: [
              {
                  title: t('Customers'), icon: Users, type: 'link', path: '/customers'
              },
          ]
      },
      {
          menutitle: t('Global conf'),
          menucontent: 'Global conf',
          Items: [
              {
                  title: t('Global conf'), icon: Settings, type: 'link', path: '/global-conf'
              },
          ]
      },
      {
          menutitle: t('Ups mails queue'),
          menucontent: 'Ups mails queue',
          Items: [
              {
                  title: t('Ups mails queue'), icon: Mail, type: 'link', path: '/ups-mails-queue'
              },
          ]
      },
    ]
  
    // remove element from menu based on the role of the user, if role is 1 don't remove
    if (role === '2') {
      // if is role 2 remove: Binaries versions, Ups mail queue, Customers, Users, Global Conf
      // remove binaries versions
      items.forEach(menuItem => {
        menuItem.Items = menuItem.Items.filter(item => item.title !== t('Binaries versions'));
      });
      // remove other elements
      let elementsToRemove = ['Ups mails queue', 'Customers', 'Users', 'Global conf'];
      items = items.filter(item => !elementsToRemove.includes(item.menucontent))
    } else if (role === '3') {
      // if is role 3 remove: Unapproved device, Ups mail queue, Customers, Groups, Users, Global Conf
      // remove unapproved device
      items.forEach(menuItem => {
        menuItem.Items = menuItem.Items.filter(item => item.title !== t('Unapproved ups devices'));
      });
      // remove other elements
      let elementsToRemove = ['Ups mails queue', 'Customers', 'Groups', 'Users', 'Global conf'];
      items = items.filter(item => !elementsToRemove.includes(item.menucontent))
    }

    return items;
  }, [role, t]);


  // eslint-disable-next-line
  const [leftArrow, setLeftArrow] = useState(false);
  const layout = id;
  const [width, setWidth] = useState(0);
  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };// eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const handleScroll = () => {
    if (window.scrollY > 400) {
      document.querySelector('.main-navbar').className =
        'main-navbar hovered';
    } else {
      if (document.getElementById('main-navbar'))
        document.querySelector('.main-navbar').className = 'main-navbar';
    }
  };

  const setNavActive = (item) => {
    MENUITEMS.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item) {
          Items.active = false;
          document.getElementById('bg-overlay1').classList.remove('active');
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
        }
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };
  useEffect(() => {
    setLeftArrow(true);
    window.addEventListener('resize', handleResize);
    handleResize();
    const currentUrl = window.location.pathname;
    MENUITEMS.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl)
          setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl)
            setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [layout, currentUrl]);

  const closeOverlay = () => {
    document.getElementById('bg-overlay1').classList.remove('active');
    document.getElementById('nav-link').classList.remove('active');
  };

  return (
    <Fragment>
      <div id="bg-overlay1" onClick={() => {
        closeOverlay();
      }} ></div>
      <header className={`main-nav ${toggleIcon ? 'close_icon' : ''}`}>
        <Profile />
        <SidebarMenu setMainMenu={setMainMenu} props={props} sidebartoogle={true} setNavActive={setNavActive} width={width} menuItems={MENUITEMS} />
      </header>
    </Fragment>
  );
};
export default SideBarLayout;