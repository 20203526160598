import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { H4, P } from '../../../AbstractElements';
import { EmailAddress, Password } from '../../../Constant';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import { firebase_app, Jwt_token } from '../../../Config/Config';
import { useStateContext } from '../../../contexts/ContextProvider';
// import man from '../../../assets/images/dashboard/1.png';
// import { handleResponse } from '../../../Services/fack.backend';
import { fetchLogin, validateToken } from '../../../api/api';
import logo from '../../../assets/images/logo/ups_central_logo_ver.svg'
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';

// function containsError(item) {
//     return Object.prototype.hasOwnProperty.call(item, 'Error');
// }
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, ['https://app/roles']);
}
function containsError(item) {
    return Object.prototype.hasOwnProperty.call(item, 'error');
}

const LoginTab = () => {
    const { setLoginResp, primary } = useStateContext();
    const [togglePassword, setTogglePassword] = useState(false);
    const [loadingLogIn, setLoadingLogIn] = useState(false);
    const navigate = useNavigate();

    const usernameRef = useRef('');
    const passwordRef = useRef('');

    const loginHandler = useCallback(async (event) => {
      event.preventDefault();
      setLoadingLogIn(true);
      const lObj = {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      };
      const login = await fetchLogin(lObj, setLoginResp);
      if (!containsError(login)) {
        const token = localStorage.getItem('auth0_token');
        const validateResp = await validateToken(token);
        if (containsSuccess(validateResp)) {
          localStorage.setItem('user_data', JSON.stringify(validateResp['https://app/user_data']));
          localStorage.setItem('auth0_user_roles', JSON.stringify(validateResp['https://app/roles']));
          localStorage.setItem('logged', true);
        } 
      }
      setLoadingLogIn(false);
      navigate('/');
    }, [navigate, setLoginResp])

    useEffect(() => {
      const handleKeyDown = (event) => {
          if (event.keyCode === 13) {
              loginHandler(event);
          }
      };

      const currentUsernameRef = usernameRef.current; // Capture the current ref value
      const currentPasswordRef = passwordRef.current; // Capture the current ref value

      // Add event listeners to input fields using the captured ref values
      currentUsernameRef.addEventListener('keydown', handleKeyDown);
      currentPasswordRef.addEventListener('keydown', handleKeyDown);

      // Clean up the event listeners using the captured ref values
      return () => {
          currentUsernameRef.removeEventListener('keydown', handleKeyDown);
          currentPasswordRef.removeEventListener('keydown', handleKeyDown);
      };
    }, [loginHandler]);

    return (
        <Fragment>
            <Form className="theme-form">
                <div style={{ textAlign: 'center' }}>
                  <img src={logo} alt='logo' />
                </div>
                <H4>Login</H4>
                <P>Enter your email & password to login</P>
                <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <div className="input-group"><span className="input-group-text"><i className="icon-email"></i></span>
                    <input className="form-control" type="email" required="" ref={usernameRef} />
                    </div>
                </FormGroup>
                <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="input-group"><span className="input-group-text"><i className="icon-lock"></i></span>
                      <input className="form-control" type={togglePassword ? 'text' : 'password'} ref={passwordRef} required="" />
                      
                    </div>
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
                </FormGroup>
                <div className="form-group mb-0">
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <Button variant='contained' style={{ backgroundColor: loadingLogIn ? 'gray' : primary }} onClick={(e) => loginHandler(e)} >{'Login'}</Button>
                    </Grid>
                    <Grid item xs={2}>
                      {loadingLogIn && <div style={{ marginTop: '12%', marginLeft: '30%' }} ><CircularProgress size={20} /></div>}
                    </Grid>
                  </Grid>
                </div>
            </Form>
        </Fragment>
    );
};

export default LoginTab;