import React from "react";
import SweetAlert from "sweetalert2/src/SweetAlert";
import Button from '@mui/material/Button';
import { setDeviceBinUpdate, getApprovedUpsDevices } from "../../../../../../api/api";
import { useStateContext } from "../../../../../../contexts/ContextProvider";
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function SetBinUpdate({ upsDevice, ToF }) {
  const { basictoaster, primary, setUpsDevices, setLoading, setExecuting } = useStateContext();
  const { t } = useTranslation();
  const binUpdate = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will schedule a binaries update'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then((result) => {
          if (result.value) {
            setExecuting(true);
            setDeviceBinUpdate(upsDevice.serial, ToF).then((response) => {
              getApprovedUpsDevices().then((resp) => {
                setUpsDevices(resp)
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Setted') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',  
                    `${t('Error setting the needs bin update field')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
            }).catch((err) => {basictoaster("error", `${t("Error setting the needs bin update field")}`, err, () => {})});
          } 
        });
  };

  return(
    <Button variant="contained" style={{ backgroundColor: primary }} onClick={binUpdate}>{t('Binary update')}</Button>
  )
}

export default SetBinUpdate;
