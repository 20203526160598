 /* eslint-disable react-hooks/exhaustive-deps */
 import React, { } from 'react';
 import { Container, Row, Col, Card, CardBody } from 'reactstrap';
 import Grow from '@mui/material/Grow';
 import { useTranslation } from 'react-i18next';

 const NoRole = () => {
  const { t } = useTranslation();
   return (
     <>
       <Container fluid={true}>
         <Row>
           <Col sm="12">
             <Grow in={true}><div>
             <Card>
               <CardBody>
                 <p><b>{t("You don't have the role to view this page")}</b></p>
               </CardBody>
             </Card>
             </div></Grow>
           </Col>
         </Row>
       </Container>
     </>
   );
 };
 
 export default NoRole;