/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Table } from 'reactstrap';
import UpdateBinVersions from './UpdateBinVersions';
import DelBinVersion from './DelBinVersion';
import { useTranslation } from 'react-i18next';

function BinVersionsTable({ binVersions }) {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <Table style={{ textAlign: 'center' }}>
        <thead className="table-primary table-responsive">
          <tr>
            <th>{t('Binary hash')}</th>
            <th>{t('Binary version')}</th>
            <th>{t('Binary name')}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {binVersions.map((version) => (
            <tr key={version.id_binary}>
              <td>{version.binary_hash}</td>
              <td>{version.binary_version}</td>
              <td>{version.binary_name}</td>
              <td><UpdateBinVersions version={version} width="20px"/></td>
              <td><DelBinVersion vId={version.id_binary} width="20px"/></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default BinVersionsTable;