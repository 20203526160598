 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getUpsMailsQueue } from '../../../api/api';
import GlobalConfTable from './UpsMailsQueueTable';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import { Button } from '@mui/material';
import NoRole from '../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const UpsMailsQueue = () => {
  const { basictoaster, loading, setLoading, upsMailsQueue, setUpsMailsQueue} = useStateContext();
  const [error, setError] = useState(false);
  const abortController = new AbortController();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getUpsMailsQueue(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsMailsQueue(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Ups mails queue Error")}`, err, () => {setError(true); setLoading(false)})});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  const refreshQueue = () => {
    setLoading(true);
    getUpsMailsQueue(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsMailsQueue(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Ups mails queue Error")}`, err, () => {setError(true); setLoading(false)})});
  }

  // ALERT
  if (isObject(upsMailsQueue) && containsError(upsMailsQueue)) {
    basictoaster("error", t("Ups mails queue Error"), upsMailsQueue.Error, setUpsMailsQueue)
  }
  
  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 2 or 3
  if (role === '2' || role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Ups mails queue")} parent={t("Ups")} title={t("Mails queue")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <Button
                  variant="contained"
                  type="button"
                  onClick={refreshQueue}
                  style={{ backgroundColor: 'gray', marginLeft: '1%' }}
                  className=""
                >
                  {t('Refresh')}
                </Button><br /><br />
                <ScrollBar style={{ height: '640px' }} className={!loading && upsMailsQueue.length > 0 ? 'shadowClass' : ''} >
                  {/* <AddBinVersion /><br /><br /> */}
                  {loading ? (
                    <div className="loader-box">
                      <Spinner attrSpinner={{ className: 'loader-1' }} />
                    </div>
                  ) : (
                    upsMailsQueue.length > 0
                      ? (
                        <Grow in={!loading}><div>
                            <GlobalConfTable upsMailsQueue={upsMailsQueue} />
                        </div></Grow>
                      )
                      : (
                        <Grow in={!loading}><div>
                          {error ? (
                            <p>{t('Ups mails queue Error')} ...</p>
                          ) : (
                            <p>{t('No mails found in queue')} ...</p>
                          )}
                        </div></Grow>
                      )
                  )}
                </ScrollBar>
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpsMailsQueue;