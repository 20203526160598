 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useStateContext } from '../../../../contexts/ContextProvider';
import '../../../../App.css'
import { Spinner } from '../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getBinVersions } from '../../../../api/api';
import AddBinVersion from './AddBinVersion';
import BinVersionsTable from './BinVersionsTable';
import { useTranslation } from 'react-i18next';
import ProgressBarModal from '../../ProgressBarModal/ProgressBarModal';
import NoRole from '../../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const BinVersions = () => {
  const { basictoaster, loading, setLoading, binVersions, setBinVersions, executing } = useStateContext();
  const { t } = useTranslation();
  const abortController = new AbortController();

  useEffect(() => {
    setLoading(true);
    getBinVersions(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setBinVersions(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster('error', `${t('Bin versions Error')}`, err, () => {})});

    // Cleanup function to abort the request when the component unmounts
    return () => {
      abortController.abort();
    };
  }, [])

  // ALERT
  const binVersionsIsObj = isObject(binVersions);
  let binVersionsIsErr = false;
  if (binVersionsIsObj) { binVersionsIsErr = containsError(binVersions); }
  useEffect(() => {
    binVersionsIsErr && (basictoaster("error", t("Bin versions Error"), binVersions.Error, setBinVersions))
  }, []);

  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 2 or 3
  if (role === '2' || role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <ProgressBarModal executing={executing} closeExecuting={() => {}} />
    <br />
      <Breadcrumbs phish mainTitle="Binaries versions" parent="Devices" title={t("Binaries versions")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <AddBinVersion /><br /><br />
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  !binVersionsIsObj && binVersions.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <BinVersionsTable binVersions={binVersions} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}>
                        <p>{t('No binaries versions found')}</p>
                      </Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BinVersions;