/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Table } from 'reactstrap';
import DelUpsGroup from './DelUpsGroup';
import ScrollBar from 'react-perfect-scrollbar';
import UpdateGroup from './UpdateGroup';
import { useTranslation } from 'react-i18next';

function UpsGroupTable({ upsGroups }) {
  const { t } = useTranslation();
  
  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <ScrollBar>
        <Table style={{ textAlign: 'center' }}>
          <thead className="table-primary table-responsive">
            <tr>
              <th>{t('Name')}</th>
              <th>{t('Customer code')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {upsGroups.length > 0 && upsGroups.map((group) => (
              <tr key={group.id_group}>
                <td>{group.group_name}</td>
                <td>{group.customer_code}</td>
                <td><UpdateGroup width="20px" group={group} /></td>
                <td><DelUpsGroup width="20px" gId={group.id_group} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollBar>
    </div>
  );
}

export default UpsGroupTable;