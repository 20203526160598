/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import { Table } from 'reactstrap';
// import UpdateGlobalConf from './UpdateGlobalConf';
import DelUpsDevice from './DelUpsDevice';
import UpsInfo from './UpsInfo/UpsInfo';
import ChangeIntervalTime from './ChangeIntervalTime';
// import { Link } from 'react-router-dom';
import { ExternalLink, CheckCircle, XCircle, AlertCircle, HelpCircle, WifiOff, Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import UpsNotifications from './UpsNotifications/UpsNotifications';

function ApprovedUpsDevicesTable({ upsDevices }) {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
        <Table style={{ textAlign: 'center' }}>
          <thead className="table-primary table-responsive">
            <tr>
              <th>{t('Status')}</th>
              <th>{t('Serial')}</th>
              <th>{t('Customer')}</th>
              <th>{t('Group')}</th>
              <th>{t('Ups name')}</th>
              <th>{t('Interval time')}</th>
              <th>{t('Installation date')}</th>
              <th>{t('SSH Enabled')}</th>
              {/* <th>ACK</th> */}
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {upsDevices.map((ups) => (
              <tr key={ups.serial}>
                <td style={{whiteSpace: "nowrap"}}>
                  {ups.status === "ONLINE" && <CheckCircle width={"100%"} style={{ color: '#069c56' }} /> }
                  {ups.status === "ONBATT" && <XCircle width={"100%"} style={{ color: '#d3212c' }} /> }
                  {ups.status === "COMMLOST" && <AlertCircle width={"100%"} style={{ color: '#ff980e' }} /> }
                  {ups.status === "NORESPONSE" && <WifiOff width={"100%"} style={{ color: 'red' }} /> }
                  {ups.status === "Unknown" && <HelpCircle width={"100%"} /> }
                </td>
                <td style={{whiteSpace: "nowrap"}}>{ups.serial}</td>
                <td style={{whiteSpace: "nowrap"}}>{ups.customer_name} | {ups.customer_code}</td>
                <td style={{whiteSpace: "nowrap"}}>{ups.group_name} | {ups.id_group}</td>
                <td style={{whiteSpace: "nowrap"}}>{ups.ups_name}</td>
                <td style={{whiteSpace: "nowrap"}}>
                  {ups.interval_time} {t('minutes')}
                  <ChangeIntervalTime ups={ups} />
                </td>
                <td style={{whiteSpace: "nowrap"}}>{ups.device_installed_date !== "" && `${new Date(ups.device_installed_date).toLocaleDateString()} ${new Date(ups.device_installed_date).toLocaleTimeString() }`}</td>
                <td style={{whiteSpace: "nowrap"}}>
                  {ups.needs_ssh_tunnel ? <Check width={20} /> : <X width={20}/>}
                </td>
                {/* <td><div className='approved'>{ups.ack}</div></td> */}
                <td><UpsNotifications ups={ups} /></td>
                <td style={{whiteSpace: "nowrap"}}>
                  <UpsInfo ups={ups} />
                  <a href={`/ups-info/${ups.serial}`} target="_blank" style={{ marginLeft: '12%' }} rel="noreferrer" >
                    <div style={{display: 'inline-block', cursor: 'pointer'}}>
                      <ExternalLink width={"100%"} />
                    </div>
                  </a>
                </td>
                <td style={{whiteSpace: "nowrap"}}><DelUpsDevice serial={ups.serial} width="20px" approved={true} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default ApprovedUpsDevicesTable;