/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Table } from 'reactstrap';
import DelUsers from './DelUsers';
import ScrollBar from 'react-perfect-scrollbar';
import UpdateUser from './UpdateUser';
import { Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

function UsersTable({ users }) {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
      <ScrollBar>
        <Table style={{ textAlign: 'center' }}>
          <thead className="table-primary table-responsive">
            <tr>
              <th>{t('Mail')}</th>
              <th>{t('Role')}</th>
              <th>{t('Customers')}</th>
              <th>{t('Enabled')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {users.length > 0 && users.map((user) => (
              <tr key={user.id}>
                <td>{user.mail}</td>
                <td>{user.role}</td>
                <td>
                  <ul>
                    {JSON.parse(user.customers).map((cus) => (
                      <li key={cus}>{cus}</li>
                    ))}
                  </ul>  
                  </td>
                <td>{user.enabled ? (<Check width="20px"/>) : (<X width="20px"/>)}</td>
                <td><UpdateUser width="20px" user={user} /></td>
                <td><DelUsers width="20px" uId={user.id} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollBar>
    </div>
  );
}

export default UsersTable;