/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Container } from 'reactstrap';
import Grid from '@mui/material/Grid';
import MulStatusChart from './MulStatusChart';
import MulLoadPctChart from './MulLoadPctChart';
import MulLineVLineFChart from './MulLineVLineFChart';
import MulBChargeChart from './MulBChargeChart';
import UpsDetails from '../../UpsInfo/UpsDashboard/UpsDetails';

function MultipleUpsDashboard({ selectedUps, selectedUpsDevice }) {
  return (
    <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <UpsDetails upsDevicesInfo={selectedUps} selectedUpsDevice={selectedUpsDevice} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MulStatusChart />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MulLoadPctChart />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MulLineVLineFChart />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MulBChargeChart />
        </Grid>
      </Grid>
    </Container>
  );
}

export default MultipleUpsDashboard;