function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

// let mettere_api_cloud = "";
// const apiServer = "192.168.254.123:8080"
const apiServer = "api.upscentral.it"

// AUTH0

// -------------------- AUTH0 LOGIN ----------------------------------
async function fetchLogin(lObj, setLoginResp) {
  try {
    const response = await fetch(`https://${apiServer}/login`, {
      method: 'POST',
      body: JSON.stringify(lObj),
    });
    const data = await response.json();
    let role = data.role;
    let token_resp = JSON.parse(data.token)
    // console.log(data.access_token);
    localStorage.setItem('auth0_token', token_resp.access_token);
    setLoginResp(token_resp);
    localStorage.setItem('db_user_role', role);
    console.log('login function');
    return data;
  } catch (error) {
    console.log(error);
    setLoginResp(error);
    throw error;
  }
}
//----------------------------------------------------------------

// -------------------- AUTH0 VALIDATE TOKEN ----------------------------------
async function validateToken() {
  try {
    const token = localStorage.getItem('auth0_token');
    const response = await fetch(`https://${apiServer}/validate_token`, {
      method: 'POST',
      body: token,
    });
    const data = await response.json();
    // console.log(data);
    console.log('validate token function');
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------

// ---------------------------- CUSTOMERS --------------------------------

// -------------------- GET CUSTOMERS ----------------------------------
function getCustomers(signal) {
  return fetch(`https://${apiServer}/get_customers`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get customers function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- GET CUSTOMERS FOR A USER ----------------------------------
function getCustomersUser(signal) {
  return fetch(`https://${apiServer}/get_customers_user`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get customers for this user function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------


// -------------------- GET CUSTOMERS ID ----------------------------------
function getCustomerId(cId) {
  return fetch(`https://${apiServer}/get_customers/${cId}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    }
  })
    .then((response) => response.json().then((data) => {
      console.log('get customers per id function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------


// -------------------- GET CUSTOMERS NAME ----------------------------------
function getCustomersName(searchName, signal) {
  return fetch(`https://${apiServer}/get_customers_name/${searchName}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get customers name function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- ADD CUSTOMERS ---------------------------------
async function addCustomers(cObj) {
  try {
    const response = await fetch(`https://${apiServer}/add_customer`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(cObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add customers function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- UPDATE CUSTOMERS ---------------------------------
async function updateCustomer(cObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_customer`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(cObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('update customers function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- DELETE CUSTOMERS ---------------------------------
async function deleteCustomer(cCode) {
  try {
    const response = await fetch(`https://${apiServer}/delete_customer/${cCode}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      }
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete customers function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//------------------------------------------------------------------------

// ---------------------------- END CUSTOMERS -------------------------------


// ---------------------------- UPS GROUPS --------------------------------

// -------------------- GET UPS GROUPS ----------------------------------
function getUpsGroups(signal) {
  return fetch(`https://${apiServer}/get_ups_groups`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups groups function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- GET UPS GROUPS ID ----------------------------------
function getUpsGroupsId(gId) {
  return fetch(`https://${apiServer}/get_ups_groups/${gId}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    }
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups groups per id function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- GET UPS GROUPS CUSTOMER ----------------------------------
function getUpsGroupsCustomer(cCode, signal) {
  return fetch(`https://${apiServer}/get_ups_groups_customer/${cCode}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups groups per customer function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- ADD UPS GROUPS ---------------------------------
async function addUpsGroups(gObj) {
  try {
    const response = await fetch(`https://${apiServer}/add_ups_groups`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(gObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add ups groups function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- UPDATE UPS GROUPS ---------------------------------
async function updateUpsGroups(gObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_ups_groups`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(gObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('update ups groups function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- DELETE UPS GROUPS ---------------------------------
async function deleteUpsGroups(gId) {
  try {
    const response = await fetch(`https://${apiServer}/delete_ups_groups/${gId}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      }
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete customers function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//------------------------------------------------------------------------

// ---------------------------- END UPS GROUPS -------------------------------

// ---------------------------- USERS --------------------------------

// -------------------- GET USERS ----------------------------------
function getUsers(signal) {
  return fetch(`https://${apiServer}/get_users`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get users function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- ADD USERS ---------------------------------
async function addUsers(gObj) {
  try {
    const response = await fetch(`https://${apiServer}/add_users`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(gObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add users function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- UPDATE USERS ---------------------------------
async function updateUsers(gObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_users`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(gObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('update users function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- DELETE USERS ---------------------------------
async function deleteUsers(gId) {
  try {
    const response = await fetch(`https://${apiServer}/delete_users/${gId}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      }
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete users function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//------------------------------------------------------------------------

// ---------------------------- END USERS -------------------------------


// ---------------------------- GLOBAL CONF ----------------------------------------------

// get global conf
function getGlobalConf(signal) {
  return fetch(`https://${apiServer}/get_global_conf`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get global conf');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}

// add global conf
async function addGlobalConf(bvObj) {
  try {
    const response = await fetch(`https://${apiServer}/add_global_conf`, {
      method: 'POST',
      body: JSON.stringify(bvObj),
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('add global conf');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// update global conf
async function updateGlobalConf(bvObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_global_conf`, {
      method: 'POST',
      body: JSON.stringify(bvObj),
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('update global conf');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// delete global conf
async function deleteGlobalConf(bvId) {
  try {
    const response = await fetch(`https://${apiServer}/delete_global_conf/${bvId}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete global conf');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// ---------------------------- END GLOBAL CONF ------------------------------------------


// ---------------------------- UPS DEVICES ----------------------------------------------

// get devices
function getUpsDevices(signal) {
  return fetch(`https://${apiServer}/get_ups_devices`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups devices');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}

// get ups devices for serial
function getUpsDevicesSerial(serial, signal) {
  return fetch(`https://${apiServer}/get_ups_devices_serial/${serial}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups devices for serial');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}


// get approved devices
function getApprovedUpsDevices(signal) {
  return fetch(`https://${apiServer}/get_approved_ups_devices`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get approved ups devices');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}

// set needs ssh tunnel
function setUpsNeedsSsh(serial, ToF) {
  return fetch(`https://${apiServer}/set_device_needs_ssh_tunnel/${serial}/${ToF}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
  })
    .then((response) => response.json().then((data) => {
      console.log('set ups needs ssh');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

// set needs bin update
function setDeviceBinUpdate(serial, ToF) {
  return fetch(`https://${apiServer}/set_device_bin_update_ui/${serial}/${ToF}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
  })
    .then((response) => response.json().then((data) => {
      console.log('set ups needs bin update');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

// update devices
async function updateUpsDevices(uObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_ups_devices`, {
      method: 'POST',
      body: JSON.stringify(uObj),
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('update ups devices');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// delete devices
async function deleteUpsDevices(serial) {
  try {
    const response = await fetch(`https://${apiServer}/delete_ups_devices/${serial}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete ups devices');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// ---------------------------- END UPS DEVICES ------------------------------------------

// ---------------------------- UPS DEVICES INFO ----------------------------------------------

// get devices info
function getAllUpsDevicesInfo(signal) {
  return fetch(`https://${apiServer}/get_all_ups_devices_info`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal,
  })
    .then((response) => response.json().then((data) => {
      console.log('get all ups devices info');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}

// get all status
function getAllUpsStatus(signal) {
  return fetch(`https://${apiServer}/get_all_ups_status`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal,
  })
    .then((response) => response.json().then((data) => {
      console.log('get all ups status');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

// get devices info
function getUpsDevicesInfo(serial, signal) {
  return fetch(`https://${apiServer}/get_ups_devices_info/${serial}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups devices info');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}

// delete devices info
async function deleteUpsDevicesInfo(serial) {
  try {
    const response = await fetch(`https://${apiServer}/delete_ups_devices_info/${serial}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete ups device info');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// ---------------------------- END UPS DEVICES ------------------------------------------

// ---------------------------- UPS NOTIFICATIONS --------------------------------

// -------------------- GET UPS NOTIFICATIONS ----------------------------------
function getUpsNotifications(serial, signal) {
  return fetch(`https://${apiServer}/get_ups_notifications/${serial}`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups notifications function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// -------------------- ADD UPS NOTIFICATIONS ---------------------------------
async function addUpsNotifications(gObj) {
  try {
    const response = await fetch(`https://${apiServer}/add_ups_notifications`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(gObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('add ups notifications function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- UPDATE UPS NOTIFICATIONS ---------------------------------
async function updateUpsNotifications(gObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_ups_notifications`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
      body: JSON.stringify(gObj),
    });
    const data = await response.json();
    // console.log(data);
    console.log('update ups notifications function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//----------------------------------------------------------------------

// -------------------- DELETE UPS NOTIFICATIONS ---------------------------------
async function deleteUpsNotifications(gId) {
  try {
    const response = await fetch(`https://${apiServer}/delete_ups_notifications/${gId}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      }
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete ups notifications function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
//------------------------------------------------------------------------

// ---------------------------- END UPS NOTIFICATIONS -------------------------------

// ---------------------------- UPS MAIL QUEUE --------------------------------

// -------------------- GET UPS MAIL QUEUE ----------------------------------
function getUpsMailsQueue(signal) {
  const timeout = 25000;
  return fetch(`https://${apiServer}/get_ups_mails_queue`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal,
    timeout
  })
    .then((response) => response.json().then((data) => {
      console.log('get ups mails queue function');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return;
      }
      console.log(error);
      throw error;
    });
}
//----------------------------------------------------------------

// ---------------------------- END UPS MAIL QUEUE -------------------------------



// ---------------------------- BIN VERSIONS ----------------------------------------------

// get bin versions
function getBinVersions(signal) {
  return fetch(`https://${apiServer}/get_bin_versions_ui`, {
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('auth0_token'),
      UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
    },
    signal: signal
  })
    .then((response) => response.json().then((data) => {
      console.log('get bin versions');
      if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
        localStorage.removeItem('logged');
        window.location.href = '/login';
      };
      return data;
    }))
    .catch((error) => {
      if (error.name === 'AbortError') {
        return
      }
      console.log(error);
      throw error;
    });
}

// add bin versions
async function addBinVersions(bvObj) {
  try {
    const response = await fetch(`https://${apiServer}/add_bin_versions`, {
      method: 'POST',
      body: JSON.stringify(bvObj),
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('add bin version');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// update bin versions
async function updateBinVersions(bvObj) {
  try {
    const response = await fetch(`https://${apiServer}/update_bin_versions`, {
      method: 'POST',
      body: JSON.stringify(bvObj),
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('update bin version function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// delete bin versions
async function deleteBinVersions(bvId) {
  try {
    const response = await fetch(`https://${apiServer}/delete_bin_versions/${bvId}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('auth0_token'),
        UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    console.log('delete binv version function');
    if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
      localStorage.removeItem('logged');
      window.location.href = '/login';
    };
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// ---------------------------- END BIN VERSIONS ------------------------------------------

export {
  fetchLogin, validateToken, getCustomers, getCustomersUser, getCustomerId, getCustomersName, addCustomers, updateCustomer, deleteCustomer,
  getUpsGroups, getUpsGroupsId, getUpsGroupsCustomer, addUpsGroups, updateUpsGroups, deleteUpsGroups,
  getUsers, addUsers, updateUsers, deleteUsers,
  getGlobalConf, addGlobalConf, updateGlobalConf, deleteGlobalConf,
  getUpsDevices, getUpsDevicesSerial, getApprovedUpsDevices, setUpsNeedsSsh, setDeviceBinUpdate, updateUpsDevices, deleteUpsDevices,
  getAllUpsDevicesInfo, getAllUpsStatus, getUpsDevicesInfo, deleteUpsDevicesInfo,
  getUpsNotifications, addUpsNotifications, updateUpsNotifications, deleteUpsNotifications,
  getUpsMailsQueue,
  getBinVersions, addBinVersions, updateBinVersions, deleteBinVersions
};





// // ---------------------------- CUSTOMER GROUPS ----------------------------------------------

// // get customer groups
// function getCustomerGroups(cId) {
//   return fetch(`https://${apiServer}/get_customer_groups/${cId}`, {
//     method: 'GET',
//     headers: {
//       Authorization: localStorage.getItem('auth0_token'),
//       UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
//     },
//   })
//     .then((response) => response.json().then((data) => {
//       console.log('get customer groups');
//       if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
//         localStorage.removeItem('logged');
//         window.location.href = '/login';
//       };
//       return data;
//     }))
//     .catch((error) => {
//       console.log(error);
//       throw error;
//     });
// }

// // add customer groups
// async function addCustomerGroups(gcVec) {
//   try {
//     const response = await fetch(`https://${apiServer}/add_customer_groups`, {
//       method: 'POST',
//       body: JSON.stringify(gcVec),
//       headers: {
//         Authorization: localStorage.getItem('auth0_token'),
//         UserMailAndRole: `${JSON.parse(localStorage.getItem('user_data')).email} ${localStorage.getItem('db_user_role')}`,
//       },
//     });
//     const data = await response.json();
//     // console.log(data);
//     console.log('add customer groups');
//     if (containsError(data) && (data.Error.includes("ExpiredSignature") || data.Error.includes("not authorized"))) {
//       localStorage.removeItem('logged');
//       window.location.href = '/login';
//     };
//     return data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }

// // ---------------------------- CUSTOMER GROUPS ----------------------------------------------