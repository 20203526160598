/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../../../contexts/ContextProvider';

const getUniqueSerials = (data) => {
  const serials = new Set();
  data.forEach((item) => {
    serials.add(item.serial);
  });
  return Array.from(serials);
};

function MulBChargeChart() {
  const { upsDevicesInfoClone } = useStateContext();
  const { t } = useTranslation();
  
  // chart options and data
  const [dataSets, setDataSets] = useState([]);
  const [datesArr, setDatesArr] = useState([]);

  useEffect(() => {
    const serialColors = {
      0: ['#069c56', '#31f79b'],
      1: ['#067e9c', '#31bcf7'],
      2: ['#9c0606', '#e37f7f'],
      3: ['#9c7906', '#f7c931'],
      4: ['#85069c', '#e031f7'],
    };
  
    const serials = getUniqueSerials(upsDevicesInfoClone);
    const datesArray = [];
  
    // Create an object to store data arrays for each serial
    const serialBattCharge = {};
  
    // Collect unique dates
    upsDevicesInfoClone.forEach((info) => {
      const date = new Date(info.added_date);
      const formattedDate = date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const formattedTime = date.toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      if (!datesArray.includes(formattedDateTime)) {
        datesArray.push(formattedDateTime);
      }
  
      // Initialize data arrays for each serial
      if (!serialBattCharge[info.serial]) {
        serialBattCharge[info.serial] = new Array(datesArray.length).fill(null);
      }
  
      // Find the index of the date in datesArray and insert b_charge into the data array
      const dateIndex = datesArray.indexOf(formattedDateTime);
      if (dateIndex !== -1) {
        serialBattCharge[info.serial][dateIndex] = info.b_charge !== '' ? parseInt(info.b_charge.replace(" Percent", "")) : 0;
      }
    });
  
    // Create datasets
    const datasets = serials.map((serial, index) => ({
      label: `Serial ${serial}: Load percentage`,
      data: serialBattCharge[serial],
      borderColor: serialColors[index][0] || 'gray',
      backgroundColor: serialColors[index][1] || 'gray',
      borderWidth: 2,
    }));
    
    setDataSets(datasets);
    setDatesArr(datesArray);
  }, [upsDevicesInfoClone]);

  const lineChartData = {
    labels: datesArr,
    datasets: dataSets,
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}
const lineChartOptions = {
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    scales: {
        xAxes: {
            display: false,
            stacked: false,
        },
        yAxes: {
            stacked: false,
        }
    },
    plugins: {
        datalabels: {
            display: false,
        }
    }
}

  return (
    <>
      <Card sx={{marginTop: "2%", position: "relative", height: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
        <CardContent>
          <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {t('Ups battery charge pct')}
          </Typography>
          <div id='basic-apex' style={{height:"100%", width: "100%" }}> 
            <Line data={lineChartData} options={lineChartOptions} width={717} height={358} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default MulBChargeChart;