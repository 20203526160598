/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import tic from '../../Icons/tic.png';
import { getUpsDevices, updateUpsDevices } from '../../../../api/api';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { Container, Modal } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import { Button } from '@mui/material';
import { SHA256 } from 'crypto-js';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsMessage(item) {
  return Object.prototype.hasOwnProperty.call(item, 'message');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function generateRandomPassword(length) {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  
  return password;
}

// Event handler to prevent whitespaces in the input
const handleKeyDown = (e) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

function ApproveAck({ ups, width }) {
  const { basictoaster, primary, setUpsDevices, setLoading, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 2);
  const [installedDate, setInstalledDate] = useState(currentDate.toISOString().slice(0, 16));
  const { t } = useTranslation();
  
  const handleOpen = () => {
    const newPassword = generateRandomPassword(16);
    setPassword(newPassword);
    setOpen(true);
    setPasswordValid(true);
  }
  const handleClose = () => {
    setOpen(false)
  };

  const passChange = (event) => {
    setPassword(event.target.value);
    if (event.target.value.trim() === '') {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  }

  // console.log(installedDate)

  const approve = () => {
    SweetAlert.fire({
        title: `${t('Are you sure')}?`,
        text: t('This will approve the device'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            // console.log(password)
            const hashedPass = SHA256(password).toString()
            // console.log(hashedPass)
            // get current date for device_approved_date
            const device_approved_date = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
            const device_installed_date = format(new Date(installedDate), "yyyy-MM-dd'T'HH:mm:ssXXX")
            const devObj = {
              serial: ups.serial,
              customer_code: ups.customer_code,
              id_group: ups.id_group,
              api_pass: hashedPass,
              ack: 'Approved ups device',
              interval_time: ups.interval_time,
              internal_ip: ups.internal_ip,
              external_ip: ups.external_ip,
              device_added_date: ups.device_added_date,
              device_approved_date: device_approved_date,
              device_installed_date: device_installed_date,
              needs_ssh_tunnel: ups.needs_ssh_tunnel,
              ssh_remote_port: ups.ssh_remote_port,
              ssh_opened_date: ups.ssh_opened_date,
            };
            await updateUpsDevices(devObj).then(async (response) => {
              setLoading(true);
              getUpsDevices().then((resp) => {
                setUpsDevices(resp)
                setLoading(false);
              }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Approved') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error approving the ups device')}: ${response.Error}.`,
                    'error'
                );
              } else if (containsMessage(response)) {
                SweetAlert.fire(
                    t('Error from api') + '!',
                    `${t('Error approving the ups device')}: ${response.message}.`,
                    'error'
                );
              }
              setExecuting(false);
              handleClose();
            }).catch((err) => {basictoaster("error", `${t("Update ups devices Error")}`, err, () => {});});;
          }
        });
  };

  return (
    <>
      <button className='imgButton' type="button" onClick={handleOpen}><img src={tic} alt="trash" style={{ width: width }} /></button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='m'
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '30px', textAlign: 'center' }} >
          <h5>{t('Select an API password')}:</h5><br />
          <FormGroup>
            <Label className="col-form-label pt-0" htmlFor="seltemp">{t('API password')}</Label>
            <Input
              type="text"
              className="custom-select"
              onKeyDown={handleKeyDown}
              onChange={passChange}
              defaultValue={password}
              name="apipass"
              id="apipass" 
            />
          </FormGroup>
          <FormGroup>
            <Label>{t('UPS installation date')}</Label>
            <input
              className="form-control"
              type="datetime-local"
              value={installedDate}
              onChange={(e) => setInstalledDate(e.target.value)}
            />
          </FormGroup>
          {passwordValid ? (
            <Button variant='contained' style={{backgroundColor: primary}} onClick={approve}>{t('Approve')}</Button>
          ) : (
            <Button variant='contained' style={{backgroundColor: primary}} disabled >{t('Approve')}</Button>
          )}
          <Button variant='contained' style={{backgroundColor: 'gray', marginLeft: '5%'}} onClick={handleClose}>{t('Close')}</Button>
        </Container>
      </Modal>
    </>
  );
}

export default ApproveAck;
