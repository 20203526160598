/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
// import UpdateGlobalConf from './UpdateGlobalConf';
import UpsInfo from '../UpsInfo/UpsInfo';
import ChangeIntervalTime from '../ChangeIntervalTime';
import { getUpsGroupsCustomer, getUpsDevicesInfo } from '../../../../../api/api';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../../AbstractElements';
import DelUpsDevice from '../DelUpsDevice';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import GraphSelectedUps from './GraphSelectedUps';
import { ExternalLink, CheckCircle, XCircle, AlertCircle, HelpCircle, WifiOff, Check, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

toast.configure();
  const basictoaster = (toastname, notifyTxt, notifyMsg, itemsDel) => {
    switch (toastname) {
      case 'error':
        toast.error(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      default:
        break;
    }
  };


function ApprovedUpsCustomerDevicesTable ({ upsDevices, customerCode }) {
  const [approvedUpsDevices, setApprovedUpsDevices] = useState(upsDevices);
  const [groups, setGroups] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [gLoading, setGLoading] = useState(false);
  const [comparing, setComparing] = useState(false);
  const [selectedUps, setSelectedUps] = useState([]);
  const [selectedUpsDevice, setSelectedUpsDevice] = useState([]);
  const [loadingComparation, setLoadingComparation] = useState(false);
  const [upsSelectedCount, setUpsSelectedCount] = useState(0);
  const [abortController, setAbortController] = useState(new AbortController());
  const { t } = useTranslation();
  
  useEffect(() => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setComparing(false);
    setSelectedUps([]);
    setUpsSelectedCount(0)
    if (customerCode !== "noCustomer") {
      setGLoading(true);
      getUpsGroupsCustomer(customerCode, newAbortController.signal).then((resp) => {
        if (!newAbortController.signal.aborted) {
          setGroups(resp)
          setGLoading(false);
        }
      })
    } else {
      setApprovedUpsDevices([])
    }
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      newAbortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [customerCode])

  useEffect(() => {
    // if the active tab is 0 it means that the active one is the 'all groups' tab so filter all groups
    if (activeTab === 0) {
      setApprovedUpsDevices(upsDevices.filter(obj => obj.ack === "Approved ups device"))
    } else {
      if (groups.length > 0) {
        // filter the ups with only the group name taken from the current active tab
        // we need to remove one from the activeTab because the 'all groups' tab has the value of 0 and the first group tab has the value of 1, but in the groups array we need to get 0 to get the first group
        // so remove one from activeTab to acces the real selected group
        setApprovedUpsDevices(upsDevices.filter(obj => obj.ack === "Approved ups device").filter(obj => obj.id_group === groups[activeTab - 1].id_group))
      }
    }
  }, [activeTab, groups, upsDevices])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const comparingHandler = (e, ups) => {
    setLoadingComparation(true);
    if (e.target.checked) {
      // add this device to selected device arr
      const updateArrDevice = [...selectedUpsDevice, ups];
      setSelectedUpsDevice(updateArrDevice)
      // get the records for this device and add them to the arr
      setUpsSelectedCount(upsSelectedCount + 1);
      getUpsDevicesInfo(ups.serial, abortController.signal).then((resp) => {
        if (isObject(resp) || containsError(resp)) {
          basictoaster("error", t("Error getting ups info"), resp.Error, () => {})
        } else {
          const updatedArr = [...selectedUps, ...resp];
          // console.log(updatedArr)
          setSelectedUps(updatedArr);
        }
        setLoadingComparation(false);
      }).catch((err) => {basictoaster("error", t("Error getting ups info"), err, () => {setComparing(false); setLoadingComparation(false)})})
    } else {
      // remove the device from device arr
      // eslint-disable-next-line no-control-regex
      const filteredArrayDevice = selectedUpsDevice.filter(obj => obj.serial !== `${ups.serial.replace(/[\s\u0000]+/g, '')}`)
      setSelectedUpsDevice(filteredArrayDevice);
      // remove the records of this device
      setUpsSelectedCount(upsSelectedCount - 1);
      // eslint-disable-next-line no-control-regex
      const filteredArray = selectedUps.filter(obj => obj.serial !== `${ups.serial.replace(/[\s\u0000]+/g, '')}`)
      // console.log(filteredArray)
      setSelectedUps(filteredArray)
      setLoadingComparation(false);
    }
  }

  // ALERT
  if (isObject(groups) && containsError(groups)) {
    basictoaster("error", t("groups Error"), groups.Error, setGroups)
  }

  return (
    <>
      {gLoading ? (
        <div className="loader-box">
          <Spinner attrSpinner={{ className: 'loader-1' }} />
        </div>
      ) : (
        <>
          {customerCode !== "noCustomer" && (
            <>
              <div  style={{ display: 'flex', justifyContent: 'center' }} >
                <Tabs value={activeTab} onChange={comparing ? null : handleTabChange} variant="scrollable" scrollButtons="auto">
                  <Tab label="All groups" />
                  {!gLoading &&
                    groups.length > 0 &&
                    groups.map((group, index) => (
                      <Tab label={group.group_name} key={index} />
                    ))
                  }
                </Tabs><br />
              </div><br />
              {comparing ? (
                <>
                  <p style={{ textAlign: 'center' }} >{t("you can't switch tab when comparing")}</p>
                  {upsSelectedCount >= 5 && <p style={{ color: 'red' }}>{t('Maximum limit of comparable UPS reached')}</p>}
                  {selectedUps.length === 0 && <p style={{ color: 'red' }} >{t('Add at least one ups with data in it')}</p>}
                  <Button variant='contained' style={{backgroundColor: 'gray'}} onClick={() => {setSelectedUps([]); setLoadingComparation(false); setComparing(false); setUpsSelectedCount(0)}}>stop comparing graphs</Button>
                  <GraphSelectedUps loadingComparation={loadingComparation} selectedUps={selectedUps} selectedUpsDevice={selectedUpsDevice} />
                  {loadingComparation && <CircularProgress size={30} style={{ marginLeft: '2%' }}  />}
                </>
              ) : (
                <Button variant='contained' style={{backgroundColor: 'gray'}} onClick={() => {setComparing(true)}}>{t('compare graphs')}</Button>
              )}<br /><br />
              {approvedUpsDevices.length > 0 ? (
                <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
                  <Table style={{ textAlign: 'center' }}>
                    <thead className="table-primary table-responsive">
                      <tr>
                        {comparing && <th />}
                        <th>{t('Status')}</th>
                        <th>{t('Serial')}</th>
                        <th>{t('Customer')}</th>
                        <th>{t('Group')}</th>
                        <th>{t('Ups name')}</th>
                        <th>{t('Interval time')}</th>
                        <th>{t('SSH Enabled')}</th>
                        {/* <th>ACK</th> */}
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {approvedUpsDevices.map((ups) => (
                        <tr key={ups.serial}>
                          {comparing && <td><Checkbox onChange={(e) => {comparingHandler(e, ups)}} disabled={loadingComparation || upsSelectedCount >= 5} /></td>}
                          <td>
                            {ups.status === "ONLINE" && <CheckCircle width={"100%"} style={{ color: '#069c56' }} /> }
                            {ups.status === "ONBATT" && <XCircle width={"100%"} style={{ color: '#d3212c' }} /> }
                            {ups.status === "COMMLOST" && <AlertCircle width={"100%"} style={{ color: '#ff980e' }} /> }
                            {ups.status === "NORESPONSE" && <WifiOff width={"100%"} style={{ color: 'red' }} /> }
                            {ups.status === "Unknown" && <HelpCircle width={"100%"} /> }
                          </td>
                          <td>{ups.serial}</td>
                          <td>{ups.customer_name} | {ups.customer_code}</td>
                          <td>{ups.group_name} | {ups.id_group}</td>
                          <td>{ups.ups_name}</td>
                          <td>
                            {ups.interval_time} {t('minutes')}
                            <ChangeIntervalTime ups={ups} />
                          </td>
                          <td>
                            {ups.needs_ssh_tunnel ? <Check width={20} /> : <X width={20}/>}
                          </td>
                          {/* <td><div className='approved'>{ups.ack}</div></td> */}
                          {/* <td><UpdateGlobalConf gcId={conf.id} width="20px"/></td> */}
                          <td>
                            <UpsInfo ups={ups} />
                            <Link to={`/ups-info/${ups.serial}`} style={{ marginLeft: '12%' }} >
                              <div style={{display: 'inline-block', cursor: 'pointer'}}>
                                <ExternalLink width={"100%"} />
                              </div>
                            </Link>
                          </td>
                          <td><DelUpsDevice serial={ups.serial} width="20px" approved={true} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <p style={{ textAlign: 'center' }} >{t('No ups for this group')}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ApprovedUpsCustomerDevicesTable;