/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../../../contexts/ContextProvider';
import { Container, Modal } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import MultipleUpsDashboard from './MultipleUpsDashboard/MultipleUpsDashboard'
import { useTranslation } from 'react-i18next';

function compareRFC3339Dates(dateA, dateB) {
  return new Date(dateA) - new Date(dateB);
}

function GraphSelectedUps({ loadingComparation, selectedUps, selectedUpsDevice }) {
  const { primary } = useStateContext();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    selectedUps.sort((a, b) => compareRFC3339Dates(a.added_date, b.added_date))
  }, [selectedUps])

  return (
    <>
      <Button variant='contained' disabled={loadingComparation || selectedUps.length === 0} style={{backgroundColor: primary, marginLeft: '1%'}} onClick={handleOpen}>{t('see selected graphs')}</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        size='xl'
        style={{maxWidth: '85%', width: '100%' }}
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <ScrollBar style={{ height: 850 }}>
          <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('Graphs for the selected ups')}</h3>
            <MultipleUpsDashboard selectedUps={selectedUps} selectedUpsDevice={selectedUpsDevice} />
            <br />
            <Button variant='contained' style={{backgroundColor: 'gray'}} onClick={handleClose}>{t('Close')}</Button>
          </Container>
        </ScrollBar>
      </Modal>
    </>
  );
}

export default GraphSelectedUps;