/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { } from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SetBinUpdate from './SetBinUpdate';

function UpsBinUpdate({ upsDevice }) {
  const { t } = useTranslation();

  return (
    <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
      <h4>{t('Bin update')}: </h4>
      <div style={{ overflow: 'scroll', width: '30%', padding: '20px', margin: 'auto' }} className='shadowClass'>
        <p><b>{t('Set bin update')}: </b></p>
        {!upsDevice.needs_bin_update ? <SetBinUpdate upsDevice={upsDevice} ToF={true}/> : "Pending bin update..."}<br /><br />
        <p>
          <b>{t('Last bin update')}: </b>{upsDevice.last_bin_update !== null ? (
            `${new Date(upsDevice.last_bin_update).toLocaleDateString()} ${new Date(upsDevice.last_bin_update).toLocaleTimeString() }`
          ) : (
            t('No last bin update found')
          )}
        </p>
      </div>
    </Container>
  );
}

export default UpsBinUpdate;