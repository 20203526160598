 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useStateContext } from '../../../../contexts/ContextProvider';
import '../../../../App.css'
import { Spinner } from '../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getUpsDevices } from '../../../../api/api';
// import AddGlobalConf from './AddGlobalConf';
import UnapprovedUpsDevicesTable from './UnapprovedUpsDevicesTable';
import { useTranslation } from 'react-i18next';
import NoRole from '../../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const UnapprovedUpsDevices = () => {
  const { basictoaster, loading, setLoading, upsDevices, setUpsDevices } = useStateContext();
  const { t } = useTranslation();
  const abortController = new AbortController();

  useEffect(() => {
    setLoading(true);
    getUpsDevices(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsDevices(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  // ALERT
  if (isObject(upsDevices) && containsError(upsDevices)) {
    basictoaster("error", t("Ups devices Error"), upsDevices.Error, setUpsDevices)
  }

  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 3
  if (role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Unapproved ups devices")} parent="Ups" title={t("Unapproved")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  upsDevices.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <UnapprovedUpsDevicesTable upsDevices={upsDevices} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}><div>
                        <p>{t('No ups devices found')}</p>
                      </div></Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UnapprovedUpsDevices;