 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { useStateContext } from '../../../../../contexts/ContextProvider'
import { toast } from 'react-toastify';
import '../../../../../App.css'
import { Spinner, Breadcrumbs } from '../../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getApprovedUpsDevices, getCustomersUser } from '../../../../../api/api';
// import AddGlobalConf from './AddGlobalConf';
import ApprovedUpsCustomerDevicesTable from './ApprovedUpsCustomerDevicesTable';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

toast.configure();
  const basictoaster = (toastname, notifyTxt, notifyMsg, itemsDel) => {
    switch (toastname) {
      case 'error':
        toast.error(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      default:
        break;
    }
  };

const ApprovedUpsCustomerDevices = () => {
  const { loading, setLoading, upsDevices, setUpsDevices, customers, setCustomers } = useStateContext();
  const [upsDevicesClone, setUpsDevicesClone] = useState(upsDevices);
  const [customerCode, setCustomerCode] = useState("noCustomer");
  const abortController = new AbortController();
  const { t } = useTranslation();
  
  useEffect(() => {
    setLoading(true)
    setCustomerCode("noCustomer")
    getCustomersUser(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setCustomers(resp);
        setLoading(false)
      }
    });
    getApprovedUpsDevices(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsDevices(resp);
      }
    });
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  function selectCustomer (event) {
    setCustomerCode(event.target.value)
    if (event.target.value === "noCustomer") {
      setUpsDevicesClone([]);
    } else {
      setUpsDevicesClone(upsDevices.filter(item => item.customer_code === parseInt(event.target.value)));
    }
  }

  // ALERT
  if (isObject(upsDevices) && containsError(upsDevices)) {
    basictoaster("error", t("Ups devices for customer Error"), upsDevices.Error, setUpsDevices)
  }
  // ALERT
  if (isObject(customers) && containsError(customers)) {
    basictoaster("error", t("Customers Error"), customers.Error, setCustomers)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Approved ups devices for customer")} parent={t("Ups for customer")} title={t("Approved")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <FormGroup>
                  <Label className="col-form-label pt-0" htmlFor="seltemp" >{t('Customer')}</Label>
                  <Input onChange={selectCustomer} type="select" className="custom-select" name="seltemp" id="seltemp">
                    <option value="noCustomer" >{t('Select a customer')}...</option>
                    {customers.length > 0 && customers.map((customer) => (
                      <option key={customer.customer_code} value={customer.customer_code} >{customer.customer_code} - {customer.customer_name}</option>
                    ))}
                  </Input>
                </FormGroup>
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  upsDevicesClone.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <ApprovedUpsCustomerDevicesTable upsDevices={upsDevicesClone} customerCode={customerCode} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}><div>
                        <p>{t('No ups devices found')} ...</p>
                      </div></Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ApprovedUpsCustomerDevices;