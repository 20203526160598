/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../../../contexts/ContextProvider';

function StatusChart() {
  const { upsDevicesInfoClone } = useStateContext();
  const { t } = useTranslation();

  // chart options and data
  const [statusChartSeries, setStatusChartSeries] = useState({
    online: 0,
    onbatt: 0,
    commlost: 0,
    noresponse: 0
  });

  // set the status chart data fot the graph
  useEffect(() => {
    const statusArrays = upsDevicesInfoClone.reduce((acc, item) => {
    // Check if there is an existing array for the current status
    if (!acc[item.status]) {
      // If not, create a new array with the current item
      acc[item.status] = [item];
    } else {
      // If an array already exists, push the item into it
      acc[item.status].push(item);
    }
    return acc;
    }, {});
    
    // Convert the object into an array of arrays
    const separatedInfo = Object.values(statusArrays);
    if (separatedInfo.length > 0) {
      let onlineArr = [];
      let onbattArr = [];
      let commlostArr = [];
      let noresponseArr = [];
      for (const separatedArr of separatedInfo) {
        for (const arr of separatedArr) {
          if (arr.status === "ONLINE") {
            onlineArr.push(arr)
          } else if (arr.status === "ONBATT") {
            onbattArr.push(arr)
          } else if (arr.status === "COMMLOST") {
            commlostArr.push(arr)
          } else if (arr.status === "NORESPONSE") {
            noresponseArr.push(arr)
          }
        }
      }
      setStatusChartSeries({
        online: onlineArr.length,
        onbatt: onbattArr.length,
        commlost: commlostArr.length,
        noresponse: noresponseArr.length,
      });
    }

  }, [upsDevicesInfoClone])

  const statusPieChartOptions = {
    chart: {
      width: 380,
      type: 'pie',
      toolbar: {
        show: false
      }
    },
    labels: ['ONLINE', 'ONBATT', 'COMMLOST', 'NORESPONSE'],
    colors: ['#069c56', '#d3212c', '#ff980e', '#000000'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 250
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  }

  return (
    <Card sx={{position: "relative", height: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
            {t('UPS Status')}
        </Typography>
        <Grid container sx={{width: "100%"}}>
          <div id='piechart' style={{textAlign: "rigth"}}>
            <Chart options={statusPieChartOptions} series={[statusChartSeries.online, statusChartSeries.onbatt, statusChartSeries.commlost, statusChartSeries.noresponse]} type="pie" width={380} />
          </div>
        </Grid>          
      </CardContent>
    </Card>
  );
}

export default StatusChart;