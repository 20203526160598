/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { addGlobalConf, getGlobalConf } from '../../../api/api';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import { Form, FormGroup, Label, Container, Modal } from 'reactstrap';
import { SHA256 } from 'crypto-js';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function containsSuccess(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Success');
}

function AddGlobalConf() {
  const { basictoaster, setGlobalConf, primary, setExecuting } = useStateContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const masterPassRef = useRef('');

  const addGlobalConfH = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('This will add the global conf'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            const hashedPass = SHA256(masterPassRef.current.value).toString()
            const gcObj = {
              master_api_password: hashedPass,
            };
            await addGlobalConf(gcObj).then(async (response) => {
              getGlobalConf().then((resp) => { setGlobalConf(resp) }).catch((err) => {basictoaster("error", `${t("Global conf Error")}`, err, () => {});});
              if (containsSuccess(response)) {
                  SweetAlert.fire(
                      t('Added') + '!',
                      response.Success,
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error adding the global conf')}: ${response.Error}.`,
                    'error'
                );
              }
              setExecuting(false);
              setOpen(false);
            }).catch((err) => {basictoaster("error", `${t("Add global conf Error")}`, err, () => {});});
          }
        });
  };

  return (
    <>
      <Button variant="contained" style={{ backgroundColor: primary }} onClick={handleOpen}>{t('Add global conf')}</Button>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        backdropClassName={open ? 'BDModalOpen' : 'BDModalClosed'}
        modalClassName={open ? 'ModalOpen' : 'ModalClosed'}
      >
        <Container fluid={true} style={{ padding: '20px' }}>
          <Form style={{ margin: '10%', marginTop: '0%', marginBottom: '1%' }}>
            <FormGroup>
              <Label htmlFor="master" className="col-form-label pt-0">{t('Master password')}</Label>
              <input className="form-control" type="text" id="master" ref={masterPassRef} />
            </FormGroup>
            <Button
              variant="contained"
              type="button"
              onClick={addGlobalConfH}
              style={{ backgroundColor: primary }}
              className=""
            >
              {t('Add')}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={handleClose}
              style={{ backgroundColor: 'gray', marginLeft: '1%' }}
              className=""
            >
              {t('close')}
            </Button>
          </Form>
        </Container>
      </Modal>
    </>
  );
}

export default AddGlobalConf;
