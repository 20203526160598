/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Container, Table } from 'reactstrap';
import ScrollBar from 'react-perfect-scrollbar';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const darkOrLigth = localStorage.getItem('mix_background_layout');
  let color = 'black';
  if (darkOrLigth === 'dark-only') { color = 'white' }

  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        style={{ color: color }}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function UpsInfoTable({ upsDevicesInfo }) {
  const upsDevicesInfoClone = [...upsDevicesInfo];
  const darkOrLigth = localStorage.getItem('mix_background_layout');
  let color = 'black';
  if (darkOrLigth === 'dark-only') { color = 'white' }
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { t } = useTranslation();
  
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - upsDevicesInfo.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function compareRFC3339Dates(dateA, dateB) {
    return new Date(dateB) - new Date(dateA);
  }

  return (
    <Container fluid={true} style={{ padding: '20px', textAlign: 'center' }}>
      <h5>{t('Info table')}:</h5><br />
      <div style={{ overflow: 'scroll', width: '100%' }} className='shadowClass'>
        <ScrollBar>
          <Table style={{ textAlign: 'center' }}>
            <thead className="table-primary table-responsive">
              <tr>
                <th>{t('Serial')}</th>
                <th>{t('Added date')}</th>
                <th>{t('Ups name')}</th>
                <th>{t('Model')}</th>
                <th>{t('Status')}</th>
                <th>{t('Line V')}</th>
                <th>{t('Load pct')}</th>
                <th>{t('B Charge')}</th>
                <th>{t('Time left')}</th>
                <th>{t('M Batt charge')}</th>
                <th>{t('Min time l')}</th>
                <th>{t('Max time')}</th>
                <th>{t('Output V')}</th>
                <th>{t('Line freq')}</th>
                <th>{t('X on batt')}</th>
                <th>{t('T on batt')}</th>
                <th>{t('Cum on batt')}</th>
                <th>{t('X off batt')}</th>
                <th>{t('Nom in V')}</th>
                <th>{t('Nom power')}</th>
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? upsDevicesInfoClone.sort((a, b) => compareRFC3339Dates(a.added_date, b.added_date)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : upsDevicesInfoClone
              ).map((upsInfo) => (
                <tr key={upsInfo.id}>
                  <td>{upsInfo.serial}</td>
                  <td>{`${new Date(upsInfo.added_date).toLocaleDateString()} ${new Date(upsInfo.added_date).toLocaleTimeString() }`}</td>
                  <td>{upsInfo.ups_name}</td>
                  <td>{upsInfo.model}</td>
                  <td>{upsInfo.status}</td>
                  <td>{upsInfo.line_v}</td>
                  <td>{upsInfo.load_pct}</td>
                  <td>{upsInfo.b_charge}</td>
                  <td>{upsInfo.time_left}</td>
                  <td>{upsInfo.m_batt_chg}</td>
                  <td>{upsInfo.min_time_l}</td>
                  <td>{upsInfo.max_time}</td>
                  <td>{upsInfo.output_v}</td>
                  <td>{upsInfo.line_freq}</td>
                  <td>{upsInfo.x_on_batt}</td>
                  <td>{upsInfo.t_on_batt}</td>
                  <td>{upsInfo.cum_on_batt}</td>
                  <td>{upsInfo.x_off_batt}</td>
                  <td>{upsInfo.nom_in_v}</td>
                  <td>{upsInfo.nom_power}</td>
                </tr>
              ))}
              {emptyRows > 0 && (
                <tr style={{ height: 53 * emptyRows }}>
                  <td colSpan={6} />
                </tr>
              )}
            </tbody>
            <TableFooter>
              <tr>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={5}
                  count={upsDevicesInfo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  style={{ color: color }}
                />
              </tr>
            </TableFooter>
          </Table>
        </ScrollBar>
      </div>
    </Container>
  );
}

export default UpsInfoTable;