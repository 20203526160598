/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getUpsGroups } from '../../../api/api';
import UpsGroupTable from './UpsGroupTable';
import AddUpsGroup from './AddUpsGroup';
import { useTranslation } from 'react-i18next';
import NoRole from '../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const UpsGroups = () => {
  const { basictoaster, loading, setLoading, upsGroups, setUpsGroups } = useStateContext();
  const abortController = new AbortController();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getUpsGroups(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsGroups(resp)
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Ups groups Error")}`, err, () => {});});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  // ALERT
  const upsGrIsObj = isObject(upsGroups);
  let upsGrIsErr = false;
  if (upsGrIsObj) { upsGrIsErr = containsError(upsGroups); }
  useEffect(() => {
    upsGrIsErr && (basictoaster("error", t("Ups groups Error"), upsGroups.Error, setUpsGroups))
  }, []);

  
  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 3
  if (role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Ups groups")} parent="Ups" title={t("Groups")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <AddUpsGroup /><br /><br />
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  !upsGrIsObj && upsGroups.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <UpsGroupTable upsGroups={upsGroups} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}>
                        <p>{t('No ups group found')}</p>
                      </Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpsGroups;