 /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import '../../../../App.css'
import { Spinner } from '../../../../AbstractElements';
import Grow from '@mui/material/Grow';
import { getApprovedUpsDevices } from '../../../../api/api';
// import AddGlobalConf from './AddGlobalConf';
import ApprovedUpsDevicesTable from './ApprovedUpsDevicesTable';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LinearProgress } from '@mui/material';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

toast.configure();
  const basictoaster = (toastname, notifyTxt, notifyMsg, itemsDel) => {
    switch (toastname) {
      case 'error':
        toast.error(`${notifyTxt}: ${notifyMsg}`, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            itemsDel({});
          }
        });
        break;
      default:
        break;
    }
  };

const ApprovedUpsDevices = () => {
  const { primary, loading, setLoading, upsDevices, setUpsDevices } = useStateContext();
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    setLoading(true);
    getApprovedUpsDevices(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsDevices(resp);
        setLoading(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
    
    // Cleanup function to abort the request when the component unmounts.
    return () => {
      abortController.abort(); // Abort the ongoing request if the component unmounts
    };
  }, [])

  function refreshUpsDevices() {
    setRefreshing(true);
    getApprovedUpsDevices(abortController.signal).then((resp) => {
      if (!abortController.signal.aborted) {
        setUpsDevices(resp);
        setRefreshing(false);
      }
    }).catch((err) => {basictoaster("error", `${t("Ups devices Error")}`, err, () => {});});
  }

  // ALERT
  if (isObject(upsDevices) && containsError(upsDevices)) {
    basictoaster("error", t("Ups devices Error"), upsDevices.Error, setUpsDevices)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Approved ups devices")} parent="Ups" title={t("Approved")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Grow in={true}><div>
            <Card>
              <CardBody>
                <Button variant='contained' style={{ backgroundColor: primary, marginRight: '2%' }} onClick={refreshUpsDevices}>{t('Refresh')}</Button><br /><br />
                {refreshing && <div style={{ width: '10%' }} ><LinearProgress /><br /></div>}
                {loading ? (
                  <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-1' }} />
                  </div>
                ) : (
                  upsDevices.length > 0
                    ? (
                      <Grow in={!loading}><div>
                        <ApprovedUpsDevicesTable upsDevices={upsDevices} />
                      </div></Grow>
                    )
                    : (
                      <Grow in={!loading}><div>
                        <p>{t('No ups devices found')}</p>
                      </div></Grow>
                    )
                )}
              </CardBody>
            </Card>
            </div></Grow>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ApprovedUpsDevices;