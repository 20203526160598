/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../App.css'
import { Spinner } from '../../../AbstractElements';
import Grow from '@mui/material/Grow';
import CustomerTable from './CustomerTable';
import AddCustomer from './AddCustomer';
import ScrollBar from 'react-perfect-scrollbar';
import SearchBar from './SearchBar';
import { useTranslation } from 'react-i18next';
import NoRole from '../NoRole/NoRole';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}
function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

const Customers = () => {
  const { basictoaster ,loading, customers, setCustomers, setSearchTerm } = useStateContext();
  const { t } = useTranslation();

  useEffect(() => {
    setSearchTerm('');
  } , [])

  // ALERT
  if (isObject(customers) && containsError(customers)) {
    basictoaster("error", t("Customers Error"), customers.Error, setCustomers)
  }

  
  let role = localStorage.getItem('db_user_role');
  // hide the page if the role is 2 or 3
  if (role === '2' || role === '3') {
    return (<NoRole />)
  }

  return (
    <Fragment>
    <br />
      <Breadcrumbs phish mainTitle={t("Customers")} parent={t("Customers")} title={t("Customers")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <ScrollBar style={{ height: 600 }}>
              <Grow in={true}><div>
                <Card>
                  <CardBody>
                    <SearchBar />
                      <AddCustomer /><br /><br />
                      {loading ? (
                        <div className="loader-box">
                          <Spinner attrSpinner={{ className: 'loader-1' }} />
                        </div>
                      ) : (
                        !isObject(customers) && customers && customers.length > 0
                          ? (
                            <Grow in={!loading}><div>
                              <CustomerTable customers={customers} />
                            </div></Grow>
                          )
                          : (
                            <Grow in={!loading}>
                              <p>{t('No customers found')}</p>
                            </Grow>
                          )
                      )}
                  </CardBody>
                </Card>
              </div></Grow>
            </ScrollBar>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Customers;