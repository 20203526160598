/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../../../contexts/ContextProvider';

function BChargeChart() {
  const { upsDevicesInfoClone } = useStateContext();
  const { t } = useTranslation();

  // chart options and data
  const [bChargeArr, setBChargeArr] = useState([]);
  const [datesArr, setDatesArr] = useState([]);
  
  // generate data for chart
  useEffect(() => {
    let bChargeArray = [];
    let datesArray = [];
    for (const info of upsDevicesInfoClone) {
      if (info.b_charge !== '') {
        bChargeArray.push(parseInt(info.b_charge.replace(" Percent", "")));
      } else {
        bChargeArray.push(0)
      }
      const date = new Date(info.added_date);
      const formattedDate = date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const formattedTime = date.toLocaleTimeString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      datesArray.push(`${formattedDate} ${formattedTime}`);
    }
    setBChargeArr(bChargeArray)
    setDatesArr(datesArray)
  }, [upsDevicesInfoClone])

  const lineChartData = {
    labels: datesArr,
    datasets: [
        {
            label: t('Battery charge percentage'),       
            data: bChargeArr,
            borderColor: '#069c56',
            backgroundColor: "#31f79b",
            borderWidth: 2,
        }
    ],
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}
const lineChartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    scales: {
        xAxes: {
            stacked: false,
        },
        yAxes: {
            stacked: false,
        }
    },
    plugins: {
        datalabels: {
            display: false,
        }
    }
}

  return (
    <>
      <Card sx={{marginTop: "2%", position: "relative", height: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
        <CardContent>
          <Typography sx={{ fontSize: 24 }} color="text.primary" gutterBottom>
              {t('Ups battery charge pct')}
          </Typography>
          <div id='basic-apex' style={{height:"100%", width: "100%" }}> 
            <Line data={lineChartData} options={lineChartOptions} width={717} height={358} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default BChargeChart;