/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import SweetAlert from 'sweetalert2/src/sweetalert2.js';
import trash from '../Icons/trash.png';
import { getGlobalConf, deleteGlobalConf } from '../../../api/api';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';

function containsError(item) {
  return Object.prototype.hasOwnProperty.call(item, 'Error');
}

function DelGlobalConf({ gcId, width }) {
  const { basictoaster, setGlobalConf, setExecuting } = useStateContext();
  const { t } = useTranslation();

  const deleteConf = () => {
    SweetAlert.fire({
        title: t('Are you sure') + '?',
        text: t('Once deleted, you will not be able to recover this conf') + '!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    })
        .then(async (result) => {
          if (result.value) {
            setExecuting(true);
            await deleteGlobalConf(gcId).then(async (response) => {
              if (response.Success) {
                  SweetAlert.fire(
                      t('Deleted'),
                      t('Conf deleted successfully'),
                      'success'
                  );
              } else if (containsError(response)) {
                SweetAlert.fire(
                    t('Error from backend') + '!',
                    `${t('Error deleting conf')}: ${response.Error}.`,
                    'error'
                );
              }
              getGlobalConf().then((resp) => { setGlobalConf(resp) }).catch((err) => {basictoaster("error", `${t("Global conf Error")}`, err, () => {});});
              setExecuting(false);
            }).catch((err) => {basictoaster("error", `${t("Delete global conf Error")}`, err, () => {});});
          }
        });
  };

  return (
      <button className='imgButton' type="button" onClick={deleteConf}><img src={trash} alt="trash" style={{ width: width }} /></button>
  );
}

export default DelGlobalConf;
